import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import UserService from '../../services/UserService';
import FilterSelect from '../form-controls/FilterSelect';
import Calendar from '../form-controls/Calendar';
import AnimateHeight from 'react-animate-height';
import DoubleDownArrow from '../icons/DoubleDownArrow';
import { useSelector } from 'react-redux';
import { useCustomers } from '../../hooks/CustomerContext';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import DateHelper from '../../helpers/DateHelper';
import Strings from './OrdersReport.Strings';
import OrderStrings from '../../translations/Orders.Strings';
import Notifications from '../../services/Notifications';

const addCustomerDropdownDescription = customers => {
    return customers.map(c => { return { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } });
}

function SalesHistoryReportFilters(props) {
    const {fetchSalesReport, isCustomerDetailsReport} = props;
    const date = new Date().setDate(new Date().getDate() - 30); // default from date current date - 30 days

    const { customerList } = useCustomers();
    const storefrontConfig = useStorefrontSettings();

    const [flatCustomerList, setFlatCustomerList] = useState(customerList);
    const [customers, setCustomers] = useState(addCustomerDropdownDescription(flatCustomerList));
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [animateHeight, setAnimateHeight] = useState("auto");
    const [territories, setTerritories] = useState([]);
    const [territory, setTerritory] = useState({});
    const [toFullDate, setToFullDate] = useState(new Date());
    const [fromFullDate, setFromFullDate] = useState(new Date(date));

    const store = props.store ? props.store : useSelector((state) => state);
    const salesHistoryConfig = useSalesHistoryConfig();
    const localized = salesHistoryConfig.labels;
    const localizedOrders = OrderStrings.localize();
    const localizedStrings = Strings.localize();
    const dateFormat = storefrontConfig.settingsList.DateFormat;

    useEffect(() => {
        let mounted = true;
        if (mounted) UserService.getUserSalesTerritories(store.user.currentUser.id, loadTerritories);
        flattenCustomers();
        return () => { mounted = false; return null; }
    }, [])

    const loadTerritories = (data) => {
        if (data) {
            data = data.map(territory => { return { ...territory, dropdownDescription: `${territory.code} - ${territory.description}` } })
            setTerritories(data);
            //if (data.length === 1) changeTerritory(data[0].code);
        }
    }

    const flattenCustomers = () => {
        let flat = [];
        flat = customerList.flatMap(c => {
            return (c.children.length > 0) ? [].concat(...c.children, c) : c;
        });
        setFlatCustomerList(flat.sort((a, b) => (a.id > b.id) ? 1 : -1));
    }

    const loadCustomers = (newTerritory) => {
        //when territory changes, filter customers by the new territory
        if (newTerritory) {
            setCustomers(flatCustomerList.flatMap(c => {
                return c.salesTerritoryCode === newTerritory ? { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } : [];
            }));
        }
        else setCustomers(addCustomerDropdownDescription(flatCustomerList));
    }

    const handleSelectedCustomer = (e) => {
        setSelectedCustomer(e.target.value);
    }

    const territoryChange = (e) => {
        const newTerritoryString = JSON.parse(JSON.stringify(e.target.value))
        const newTerritory = territories.find((territory) => `${territory.code} - ${territory.description}` == newTerritoryString) ?? {};
        setTerritory(newTerritory);
        if (selectedCustomer !== 0) handleSelectedCustomer({ target: { value: 0 } });
        loadCustomers(newTerritory?.code);
    }

    const handleSetFromDate = (e) => {
        const currentDate = new Date();
        const toDate = toFullDate;
        // From Date can't be in the future
        var futureFromDateResult = DateHelper.compareDates(e, currentDate);
        // From Date must be less than to date
        var result = DateHelper.compareDates(e, toDate);
        if (futureFromDateResult == 1) {
            return Notifications.error(localized.FromDateFutureError);
        }
        else if (result == 1) {
            return Notifications.error(localized.FromDateToDateError);
        }
        else {
            setFromFullDate(e);
        }
    };

    const handleSetToDate = (e) => {
        const fromDate = fromDate;
        // To Date must be greater than from date
        const parseToDate = Date.parse(e);
        const parseFromDate = Date.parse(fromDate);
        if (parseToDate < parseFromDate) {
            return Notifications.error(localized.ToDateError);
        }
        setToFullDate(e);
    };

    return (
        <div className="fele-order-history-filter">
            <div className="button-wrapper filter-trigger">
                <button data-cy="filter-trigger" className="button" onClick={() => setAnimateHeight(animateHeight === 0 ? "auto" : 0)}>
                    {localizedOrders.SearchForOrders}
                    <DoubleDownArrow />
                </button>
            </div>
            <AnimateHeight duration={500} height={animateHeight}>
                <div className="flex">
                    <FilterSelect wrapperClass={'report-status'} label={localized.SelectTerritoryLabel} onChange={territoryChange} value={territory.dropdownDescription}
                        placeholder={localized.SelectTerritoryPlaceholder} options={territories} valueField={'externalId'} descriptionField={'dropdownDescription'} />
                    {   !isCustomerDetailsReport &&
                        <>
                            <FilterSelect wrapperClass={'report-status'} label={localizedStrings.SelectCustomerLabel} onChange={handleSelectedCustomer} value={selectedCustomer}
                                placeholder={localized.SelectCustomerPlaceholder} options={customers} valueField={'externalId'} descriptionField={'dropdownDescription'} />
                            <div className="from-to-dates" data-cy="order-from-date">
                                <div className="label">{localized.FromDate}</div>
                                <div className="fele-content">
                                    <div className="input-wrapper">
                                        <Calendar date={fromFullDate} minDate={new Date().setDate(new Date().getDate() - 730)}
                                            showIcon={true} callBackFunction={handleSetFromDate} dateFormat={dateFormat} localized={localized} />
                                </div>
                            </div>
                        </div>
                        <div className="from-to-dates" data-cy="order-to-date">
                            <div className="label">{localized.ToDate}</div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <Calendar date={toFullDate} minDate={new Date().setDate(new Date().getDate() - 730)}
                                        showIcon={true} callBackFunction={handleSetToDate} dateFormat={dateFormat} />
                                </div>
                            </div>
                        </div>
                        </>
                    }
                    <div className="search">
                    <div className="button-wrapper small">
                            <div className="label">&nbsp;</div>
                            {((territory === {} || selectedCustomer === "") && isCustomerDetailsReport) && <button className="button search-button" disabled>{localizedOrders.SearchInputLabelPlaceholder}</button>}
                            {(territory == !{} || selectedCustomer !== "" || !isCustomerDetailsReport) &&
                                <button data-cy="" className="button search-button button-alt" onClick={() => { !isCustomerDetailsReport ? fetchSalesReport(fromFullDate, toFullDate, selectedCustomer, territory.code, territory.companyCode) : fetchSalesReport(territory.code, territory.companyCode)}}>
                                {localizedOrders.SearchButtonText}
                            </button>
                        }
                    </div>
                    </div>
                </div>
            </AnimateHeight>
        </div>

    );
}

SalesHistoryReportFilters.propTypes = {
    fetchSalesReport: PropTypes.func,
    isCustomerDetailsReport: PropTypes.bool
}

export default SalesHistoryReportFilters;