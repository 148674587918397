import React from 'react';
import PropTypes from 'prop-types';
import CartItem from './CartItem';

export default class CartItems extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        if (!this.props.items || this.props.items.length < 0)
            return null;
        return (
            <div className="fele-cart-items">
                {
                    this.props.items.map((item, key) => (
                        <CartItem
                            key={key}
                            item={item}
                            itemId={item.id}
                            currentUser={this.props.currentUser}
                            selectedCustomer={this.props.selectedCustomer}
                            dateFormat={this.props.dateFormat}
                            removeItem={this.props.removeItem}
                            updateCartItem={this.props.updateCartItem}
                            updateLineItemQuantity={this.props.updateLineItemQuantity}
                            pCDiscountCodes={this.props.pCDiscountCodes}
                            requestDate={this.props.requestDate} />
                    ))
                }
            </div>
        )
    }
}

CartItems.propTypes = {
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
    dateFormat: PropTypes.string,
    items: PropTypes.array,
    updateLineItemQuantity: PropTypes.func,
    removeItem: PropTypes.func,
    updateCartItem: PropTypes.func,
    components: PropTypes.object,
    pCDiscountCodes: PropTypes.object,
    requestDate: PropTypes.object
};