import React, { useState } from 'react';
import DateDisplay from '../DateDisplay';
import Money from '../Money';
import { withRouter } from "react-router-dom";
import DateHelper from '../../helpers/DateHelper';
import { useSelector } from 'react-redux';
import Configuration from '../../resources/Configuration';
import CompletePricing from './CompletePricing';
import { useSalesHistoryConfig} from '../../hooks/SalesHistoryConfigContext';
import OrderService from '../../services/OrderService';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';
import TaxByLine from './TaxByLine';
import Notifications from '../../services/Notifications';

const OrderItem = props => {
    const salesHistoryConfig = useSalesHistoryConfig();
    const localized = salesHistoryConfig.labels;
    const childComponents = salesHistoryConfig.childComponents;
    const localizedStatuses = localized;
    const store = props.store ? props.store : useSelector((state) => state);
    const [showCompletePricing, setShowCompletePricing] = useState(false);
    const [showLineTaxes, setShowLineTaxes] = useState(false);
    const storefront = store.user.currentUser.storefront;
    const storefrontConfig = useStorefrontSettings();

    const [completeLinePricingData, setCompleteLinePricingData] = useState([{ DescAdjName: "", FactorValueNumeric: 0, UnitPrice: 0, Override: false, BasePrice: false }]);
    const [lineTaxData, setLineTaxData] = useState([{ Description: "", AdjustmentControlCode: "", FactorValue: 0, ExtendedTaxAmount: 0 }]);
    let item = props.item;
    if (!item) return null
    const excludeStatuses = [localizedStatuses.Shipped, localizedStatuses.Complete, localizedStatuses.Cancelled,
        localizedStatuses.PartiallyShipped, localizedStatuses.Completed];
    const handleClickInnerLinePricing = e => {
        e.stopPropagation();
        return OrderService.getCompleteLinePricing(item.orderId, item.orderType, item.orderCompany, item.lineNumber, storefrontConfig.locale).then((data) => {
            if (data) {                    
                setCompleteLinePricingData(data);
                setShowCompletePricing(true);
            }
        });       
    }

    const handleClickInnerLineTaxes = e => {
        e.stopPropagation();
        return OrderService.getOrderLineTaxes(item.orderId).then((taxes) => {
            if (taxes.length != 0 && taxes) {
                setLineTaxData(taxes.filter((x) => x.lineNumber == item.lineNumber && x.orderType == item.orderType))
                setShowLineTaxes(true);
            }
            else {
                Notifications.error("No additional tax data found.")
            }
        });
    }

    const handleClickOuter = () => {        
            props.history.push({ pathname: "/product/" + props.item.productSku, state: { selectedCustomer: props.selectedCustomer } });
    }
    const now = DateHelper.getNewDate();

    return (
        <div className="wrapper">            
            <div onClick={handleClickOuter} className="item">               
                <div className="left-side">
                    <div className="left-info-items">
                        <div>
                            <div className="part-name">
                                <div className="fele-content">{item.productName}</div>
                            </div>
                            <div className="part-number">
                                <div className="fele-content">{item.productSku}</div>                    
                            </div>
                            <div className="part-description">
                                <div className="fele-content">{item.productDescription}{item.productGeneralDescription && item.productGeneralDescription.length > 0 ? "," + item.productGeneralDescription : ""}</div>
                            </div>
                        </div>
                        <div>
                            <div>
                                {childComponents.LinePaymentTerms.shown &&
                                    <div className="button-wrapper pricing-button pricing-button-text">
                                        <button className="button button-secondary button-padding" onClick={handleClickInnerLinePricing}>
                                            {localized.ViewCompletePricingDetailsLabel}
                                        </button>
                                    </div>
                                }
                            </div>
                            <div>
                                {childComponents.AdditionalLineTaxes.shown &&
                                    <div className="button-wrapper additional-taxes-button pricing-button-text">
                                        <button className="button button-secondary button-padding" onClick={handleClickInnerLineTaxes}>
                                            {localized.ViewExtendedLineTaxes}
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="left-info-items">
                        <div className="quantity">
                            <div className="label">{localized.QtyLabel}:</div>
                            <div className="fele-content">{item.quantity}</div>
                        </div>
                        <div className="each">
                            <div className="label">{localized.UnitPrice}: </div>
                            <Money value={item.unitPrice_Amount} currency={item.price_Currency} country={props.selectedCustomer.address.country} />
                        </div>
                        <div className="total">
                            <div className="label">{localized.Total}: </div>
                            <Money value={item.price_Amount} currency={item.price_Currency} country={props.selectedCustomer.address.country} />
                        </div>
                        {childComponents.LinePaymentTerms.shown === true &&
                            <div className="paymentTerms">
                                <div className="label">{localized.PaymentTermsLabel}:</div>
                                <div className="fele-content">{item.paymentTerms} - {item.paymentTermsDescription}</div>
                            </div>
                            }
                    </div>                   
                </div>
            <div className="right-side">
                <div className="reference-number">
                    <div className="label">{localized.PONumber}</div>
                    <div className="fele-content">{item.referenceNumber}</div>
                </div>
                <div className="invoice-number">
                    <div className="label">{localized.InvoiceNumber}</div>
                    <div className="fele-content">{item.invoiceNumber}</div>
                </div>
                {
                        (!item.hideEstimatedShipDate && childComponents.EstimatedShipDate.shown) &&
                    <div className="ship-date">
                        <div className="label">{localized.ShipDate}</div>
                        <div className="fele-content">
                            {
                                // For Fueling storefront, estimated dates in the past, Status is not shipped, 'Contact Customer Service'
                                (storefront === Configuration.storefronts.fuelingStorefront &&
                                    DateHelper.compareDates(item.estimatedDate, now) === -1 &&
                                    !excludeStatuses.includes(item.lineStatus)) ?
                                    <div className="fele-content">{localized.ContactCustomerService}</div>
                                            : 
                                            <>
                                                {(!excludeStatuses.includes(item.lineStatus))
                                                    ? <>
                                                        {(DateHelper.compareDates(item.estimatedDate, item.orderDate) === -1)
                                                            ? <div className="fele-content">{"NA"}</div>
                                                            : <DateDisplay date={item.estimatedDate} dateFormat={props.dateDisplayFormat} />
                                                        }
                                                    </>
                                                    : <>
                                                        {(DateHelper.compareDates(item.shipDate, item.orderDate) === -1)
                                                            ? <div className="fele-content">{"NA"}</div>
                                                            : <DateDisplay date={item.shipDate} dateFormat={props.dateDisplayFormat} />
                                                        }
                                                    </>
                                                }
                                            </>
                                    }  
                        </div>
                    </div>
                    }
                    {childComponents.RequestedDate.shown &&
                        <div className="request-date">
                            <div className="label">{localized.RequestDate}</div>
                            <div className="fele-content">
                                <DateDisplay date={item.requestDate} />
                            </div>
                        </div>
                    }
            </div>
            </div>
            {showCompletePricing &&
                <CompletePricing
                    isOpen={showCompletePricing}
                    onClose={() => setShowCompletePricing(false)}
                    completeLinePricingData={completeLinePricingData}>
                    
                </CompletePricing>               
            }
            {showLineTaxes &&
                <TaxByLine
                    isOpen={showLineTaxes}
                    onClose={() => setShowLineTaxes(false)}
                    lineTaxData ={lineTaxData}>
                </TaxByLine>
            }             
        </div>

    );


   

   
    
}

export default withRouter(OrderItem);