import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import CalendarIcon from '../icons/CalendarIcon';
import Strings from './Calendar.Strings';
import { InputGroup } from 'react-bootstrap';
import { registerLocale } from 'react-datepicker';
import ptb from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
registerLocale("pt-BR", ptb);
registerLocale("en-US", en);
registerLocale("es", es);
registerLocale("it-IT", it);
import DateHelper from '../../helpers/DateHelper';
import it from 'date-fns/locale/it';

const getDateFormat = (propsDateFormat) => {
    let dateFormat = propsDateFormat ? propsDateFormat : "MM/dd/yyyy";
    return dateFormat;
}



const Calendar = props => {
    const localized = Strings.localize();
    const rootMinDate = new Date(props.minDate);
    rootMinDate.setHours(0, 0, 0, 0);
    const calendarSelectedDate = props.date === null ? null : (props.date >= rootMinDate ? props.date : rootMinDate);
    const dateInputChanged = (date, e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        if (!rootMinDate || date >= rootMinDate) {
            let _d = DateHelper.getUTCDate(date);
            return props.callBackFunction(_d);
        }
    }
    const isWeekday = (date) => {        
        if (props.filterWeekends) {   
            const day = date.getDay();
            return day !== 0 && day !== 6;
        }
        else {
            return true;
        }
    };

    return (
        <div className="fele-calendar " style={props.disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}>
            <label className="datepicker-wrapper" >
                <InputGroup className="mb-3">
                    {
                        props.showIcon &&
                        <InputGroup.Prepend>
                            <InputGroup.Text><CalendarIcon /></InputGroup.Text>
                        </InputGroup.Prepend>
                    }
                    <DatePicker selected={calendarSelectedDate ? DateHelper.checkDateIsString(calendarSelectedDate, props.dateFormat) : null}
                        minDate={rootMinDate} locale={localized.lang} startDate={rootMinDate} filterDate={isWeekday} allowSameDay={true}
                        onChange={dateInputChanged} dateFormat={getDateFormat(props.dateFormat)} className={`form-control ${props.needsValidated ? (props.dateError || calendarSelectedDate === null ? "is-invalid" : "is-valid") : ""}`} />
                </InputGroup>
            </label>
        </div>
    );
}

Calendar.propTypes = {
    date: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ])
};
export default Calendar;