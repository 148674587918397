import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import StorefrontService from '../../services/Storefront';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';

const StorefrontPersona = props => {

    const storefrontConfig = useStorefrontSettings();
    const [isCustAdmin] = useState(props.currentUser.persona.name !== "Administrator");
    const [personaList, setPersonaList] = useState(props.personaList || []);
    const [selectedStorefront, setSelectedStorefront] = useState((isCustAdmin) ? getUserStorefrontValue() : props.currentStorefront || {});
    const [selectedPersona, setSelectedPersona] = useState(props.currentPersona || {});
    const [storefrontOptions] = useState(getOptions() || []);

    useEffect(() => {
        let storefront = storefrontConfig.getStorefrontById(selectedStorefront.value);
        if (storefront && storefront.number) {
            fetchPersonaList(storefront.number);
        }
    }, [selectedStorefront]);

    function getUserStorefrontValue() {
        var s = storefrontConfig.getStorefrontById(props.currentUser.storefrontId);

        return { value: s.id, label: s.displayName };
    }

    function getOptions() {
        var result = [];

        storefrontConfig.storefrontList.forEach((s) => {
            if (s.number !== '00000') {
                result.push({ value: s.id, label: s.displayName });
            }
        });

        return result;
    }

    function fetchPersonaList(storefrontNumber) {
        StorefrontService.getPersonas(storefrontNumber).then((res) => {
            // populate persona list
            var list = [];
            res.forEach((p) => {
                if ((isCustAdmin && p.displayName !== "Administrator") || props.currentUser.persona.name === "Administrator") {
                    list.push({ value: p.id, label: p.displayName });
                }
            });
            setPersonaList(list);
            // if selected Persona is not in "list", clear out selected value.
            let p = list.find(l => l.value === selectedPersona.value);
            if (!p) {
                p = { value: '', label: '' };
                setSelectedPersona(p);
            }
            props.updateStorefrontPersona(selectedStorefront, p);
        });
    }

    function storefrontOnChange(e) {
        setSelectedStorefront(e);
    }

    function personaOnChange(e) {
        setSelectedPersona(e);
        props.updateStorefrontPersona(selectedStorefront, e);
    }

    return (
        <div className="w-100 d-flex justify-content-between">
            <div style={{ width: '48%' }}>
                <div className="label">
                    Storefront:
                </div>
                <Select options={storefrontOptions} onChange={storefrontOnChange} value={selectedStorefront} placeholder={'Select a storefront'} isDisabled={isCustAdmin} />
            </div>
            <div style={{ width: '48%' }}>
                <div className="label">
                    Persona:
                </div>
                <Select options={personaList} onChange={personaOnChange} value={selectedPersona} />
            </div>
        </div>);
}

export default StorefrontPersona;