import React from 'react';
import PropTypes from 'prop-types';

export default class Comments extends React.Component {

    constructor(props) {
        super(props);
       
        this.state = {
            comments: props.comments,
            editable: props.editable,
        };

    }

    handleChange = (e) => {
        let comments = e.target.value;
        this.props.commentsUpdated(comments);
        this.setState({ comments: comments });
        
        
    }

    render() {
        const { comments } = this.state;
        const { editable } = this.state;
        return (
            <div className="fele-cart-comments">
                <div className="fele-content">
                    <div className="input-wrapper">

                        <textarea value={comments} onChange={this.handleChange} onBlur={this.handleChange} readOnly={editable} autoResizeEnabled={true}
                            minHeight={100} maxHeight={200} style={{ height: '300px'}} />
                    </div>
                </div>
            </div>
        )
    }
}

Comments.propTypes = {
    commentsUpdated: PropTypes.func,
    comments: PropTypes.string,
};