import LocalizedStrings from 'react-localization';
import Localization from './../../services/Localization';

class AvailabilityStrings {
    localized = new LocalizedStrings({
        en: {
            Available: "Available",
            Backordered: "Backordered",
            PartiallyAvailable: "Partially Available",
            Unavailable: "Unavailable",
            Unknown: "Unknown"
        },

        pt: {
            Available: "Disponível",
            Backordered: "Sem Estoque",
            PartiallyAvailable: "Em espera parcial",
            Unavailable: "Indisponível",
            Unknown: "Não encontrado"
        },

        es: {
            Available: "Disponible",
            Backordered: "Fuera de stock",
            PartiallyAvailable: "Parcialmente Disponible",
            Unavailable: "No disponible",
            Unknown: "No encontrado"
        },

        it: {
            Available: "Disponibile",
            Backordered: "Non disponibile",
            PartiallyAvailable: "Partially Available",
            Unavailable: "Non Disponibile",
            Unknown: "Unknown"
        },
        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new AvailabilityStrings();
