
import { toast } from 'react-toastify';

class Notifications {
    error(message) {
        toast.error(message, { ...defaults() });
    }
    errorLong(message) {
        toast.error(message, { ...defaults(), autoClose: 12000 });
    }

    message(message) {
        toast.info(message, { ...defaults() });
    }

    confirmation(message, callback) {
        toast.info(buttonsHTML(message, callback), { ...defaults() });
    }

    // This is for the users to show the error toast till user clicks on close icon
    errorShow(message) {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: false
        });
    }
    warning(message) {
        toast.warning(message, { ...defaults() });
    }

}

const buttonsHTML = (message, callback) => (
    <div className="buttons">
        <div>
            {message}
        </div>
        <div className="button-wrapper">
            <button className='button' data-cy='confirm-yes' onClick={callback}>YES</button>
        </div>
        <div className="button-wrapper">
            <button className='button' onClick={() => toast.dismiss()}>NO</button>
        </div>
    </div>
);

function defaults() {
    return {
        position: toast.POSITION.TOP_RIGHT,
    }
}

export default new Notifications();