import { React, useState, useRef } from 'react';
import { withWarrantyConfig } from '../../hooks/WarrantyConfigContext';
import DateHelper from '../../helpers/DateHelper';
import ErrorIcon from '../icons/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import ClaimAttachment from '../claims/ClaimAttachment';
import CommentDetailsModal from '../claims/CommentDetailsModal';
const ClaimLine = (props) => {

    const { labels: localized } = props.WarrantyConfig;
    const [collapsed, setCollapsed] = useState(false);
    const [count, setCount] = useState(0);

    const [itemNumberSubmit, setItemNumberSubmit] = useState('');
    const [serialNumberSubmit, setSerialNumberSubmit] = useState('');
    const [detailCommentSumit, setDetailCommentSumit] = useState('');
    const [showDetailModal, setShowDetailModal] = useState(false);
    const clickableStyle = {
        cursor: 'pointer'
    }
    
  
    const remove = (e) => {
        props.removeItem(e.target.value);
    }

    const setShowModal = () => {
        setCollapsed(!collapsed);
    }
    const filesCount = (e) => {
        setCount(e);
    }
    
    const openDetailsModal = (claimLine) => {
       // console.log(claimLine);

        setItemNumberSubmit(claimLine.itemNumber);
        setSerialNumberSubmit(claimLine.serialNumber);
        setDetailCommentSumit(claimLine.detailsComments);
        setShowDetailModal(true);
    }

    const hideConfirmation = () => {
        setShowDetailModal(false);
    }

    const detailCommentUpdated = (detailComment) => {

    }

    const { claimLine, value, uploadFiles } = props;
    return (
        <div className="fele-order-history-filter">
        <div key={value} className="flex" style={{ marginLeft: '1rem' }}>
            <div className="br-claim-submission-text">
                <div className="label"></div>
                <div className="fele-content">
                    {
                        claimLine.isValid == false &&
                        <span className="error-icon-span">
                            <ErrorIcon></ErrorIcon>
                        </span>
                    }
                    {claimLine.itemNumber}
                </div>
            </div>
            <div className={claimLine.isValid == false ? "br-claim-serialNumber-text" : "br-claim-submission-text"}>
                <div className="fele-content">{claimLine.serialNumber}</div>
            </div>
            <div className="br-claim-submission-text">
                <div className="fele-content">{claimLine.loyaltyID}</div>
            </div>
            <div className="br-date">
                <div className="fele-content">{DateHelper.format(claimLine.dateInstalled, "MM/dd/yyyy")}</div>
            </div>
            <div className="br-date">
                <div className="fele-content">{DateHelper.format(claimLine.dateFailed, "MM/dd/yyyy")}</div>
            </div>
            <div className="br-claim-refNo">
                <div className="fele-content">{claimLine.referenceNumber}</div>
            </div>
            <div className="claim-search">
                <div className="label"></div>
                <div className="fele-content">{claimLine.comments}</div>
                {
                    claimLine.detailsComments != "" && claimLine.detailsComments != null &&
                    <div className="button-wrapper" style={{ minWidth: '7rem', height: '1.7rem' }} >
                        <button onClick={() => openDetailsModal(claimLine)} style={clickableStyle}>
                            <a className="link" style={{ fontWeight: "bold", fontSize: '1.2rem' }} >{"+" + localized.DetailedComments}</a>
                        </button>
                    </div>
                }
            </div>
            <div className="br-claim-search">
                <div className="label"></div>
                <div className="fele-content">{claimLine.claimType?.name}</div>
            </div>
            {
               claimLine.isPioneerItem && props.WarrantyConfig.settings.ShowAttachments === true &&
                <div className="claim-attachment">
                    <a onClick={remove} onClick={() => setShowModal()} >
                        <FontAwesomeIcon icon={faPaperclip} color="green" /> {count && count > 0 ? count : ''}
                    </a>
                </div>
            }
            <div className="br-claim-submission-button">              
                <div className="fele-content">                  
                    <div className="button-wrapper small">
                        <button className="button search-button button-alt" value={value} onClick={remove}>{"- "+localized.RemoveItem }</button>
                    </div>
                </div>
            </div>
            <ClaimAttachment localized={localized} uploadFiles={uploadFiles} filesCount={filesCount} collapsed={collapsed} claimLine={claimLine} setShowModal={setShowModal} />
            <CommentDetailsModal
                show={showDetailModal}
                onHide={hideConfirmation}
                callback={hideConfirmation}
                localized={localized}
                itemNumber={itemNumberSubmit}
                serialNumber={serialNumberSubmit}
                detailComment={detailCommentSumit}
                detailCommentUpdated={detailCommentUpdated}
                showFooter={false}
                IsDetailOnly={true}
            />
        </div >
        </div>
    )
}

ClaimLine.propTypes = {
    uploadFiles: PropTypes.func,
    filesCount: PropTypes.func
}

export default withWarrantyConfig(ClaimLine);