import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import Api from '../../services/Api';
import DateDisplay from '../DateDisplay';
import Money from '../Money';
import MessageStandard from '../MessageStandard';
import AccountIcon from '../icons/Account';
import DateHelper from '../../helpers/DateHelper';
import { useCustomers } from '../../hooks/CustomerContext';
import { useAccountSummaryConfig } from '../../hooks/AccountSummaryConfigContext';
import MessageWarning from '../MessageWarning';
import UserHelper from '../../helpers/UserHelper';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';

export const getState = state => state;

const AccountSummary = props => {
    const AccountSummaryConfig = useAccountSummaryConfig();
    const { settingsList }  = useStorefrontSettings();
    const { currentCustomer: selectedCustomer } = useCustomers();
    const [fetching, setFetching] = useState(props.fetching ? props.fetching : true);
    const [summary, setSummary] = useState();
    const [error, setError] = useState(false);
    let localized = AccountSummaryConfig.labels;
    const now = DateHelper.getNewDate();

    const handleError = (e) => {
        setError(true);
        setFetching(false);
    }
    function loadSummary(newSummary) {
        if (newSummary) {
            if (newSummary.lastPaymentDate) {
                newSummary.lastPaymentDate = DateHelper.checkDateIsString(newSummary.lastPaymentDate);
            }
            setFetching(false);
            setSummary(newSummary);
        }
        else
            handleError();
    }

    const fetchSummaryData = props.fetchSummaryData ? props.fetchSummaryData : async (url) => {
        return Api.fetch(url);
    }

    const fetchSummary = () => {
        if (selectedCustomer) {
            setFetching(true);

            fetchSummaryData(`/api/account/summary/${selectedCustomer.billToId}/${selectedCustomer.companyCode}`)
                .then(loadSummary)
                .catch(handleError);
        } else {
            history.push({ pathname: '/', state: {} });
        }
    }

    useEffect(() => {
        let mounted = true;
        if (mounted && !hasShipToAddressType) fetchSummary();
        return () => { mounted = false; return null; }
    }, [selectedCustomer])

    const asOfDate = DateHelper.getNewDate();
    const hasShipToAddressType = UserHelper.isShipToCustomer(selectedCustomer?.addressType);
    return (
        <div className="fele-account-summary">
            {
                <React.Fragment>
                    {
                        hasShipToAddressType &&
                        <div>
                            <MessageWarning
                                title={localized.ShipToNotSelectedWarningTitle}
                                message={localized.AccountSummaryShipToWarningMessage}
                            />
                        </div>
                    }
                    {
                        fetching && !summary && !hasShipToAddressType &&
                        <Loading type="brand" />
                    }
                    {
                        !fetching && isEmpty(summary) && !error && !hasShipToAddressType &&
                        <div className="no-account-info">
                            <MessageStandard
                                icon={<AccountIcon />}
                                line1={localized.NoAccountInfoMessage} />
                        </div>
                    }
                    {
                        !fetching && error && !hasShipToAddressType &&
                        <div className="error">
                            <MessageStandard
                                icon={<AccountIcon />}
                                line1={localized.AccountSummaryErrorMessage} />
                        </div>
                    }
                    {
                        !fetching && summary && !isEmpty(summary) && !hasShipToAddressType &&
                        <React.Fragment>
                            <div className="summary-wrapper balance">
                                <div className="wrapper">
                                    <div className="heading2">{localized.YourBalanceAsOf}: <span className="as-of-date">
                                        <DateDisplay date={asOfDate} dateFormat={settingsList.DateDisplayFormat} />
                                    </span>
                                    </div>
                                    <div className="item payment-term">
                                            <div className="label">{localized.EstablishedPaymentTerm_Label}:</div>
                                            <div className="value">{summary.paymentTerm} - {summary.paymentTermsDescription}</div>
                                    </div>
                                    {
                                        AccountSummaryConfig.childComponents.CreditLimit.shown === true &&
                                        <div className="item credit-limit">
                                            <div className="label">{localized.CreditLimit_Label}:</div>
                                            <div className="value">
                                                <Money value={summary.creditLimit.amount} currency={summary.creditLimit.currency} country={selectedCustomer.address.country} />
                                            </div>
                                        </div>
                                    }
                                    <div className="item summary-due">
                                        <div className="label">{localized.AccountBalanceDue_Label}:</div>
                                        <div className="value">
                                            <Money value={summary.balanceDue.amount} currency={summary.balanceDue.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                    {
                                        AccountSummaryConfig.childComponents.OpenOrderAmount.shown === true &&
                                        <div className="item open-orders">
                                            <div className="label">{localized.OpenOrders_Label}:</div>
                                            <div className="value">
                                                <Money value={summary.openOrders.amount} currency={summary.openOrders.currency} country={selectedCustomer.address.country} />
                                            </div>
                                        </div>
                                    }
                                    {
                                        AccountSummaryConfig.childComponents.CreditLimit.shown === true &&
                                        <div className="item over-credit-limit">
                                            <div className="label">{localized.OverCreditLimit_Label}:</div>
                                            <div className="value">
                                                <Money value={summary.overCreditLimit.amount} currency={summary.overCreditLimit.currency} country={selectedCustomer.address.country} />
                                            </div>
                                        </div>
                                    }
                                    {
                                        AccountSummaryConfig.childComponents.PastDueAccountBalance.shown === true &&
                                        <div className="item past-due">
                                            <div className="label">{localized.AccountBalancePastDue_Label}:</div>
                                            <div className="value">
                                                <Money value={summary.pastDue.amount} currency={summary.pastDue.currency} country={selectedCustomer.address.country} />
                                            </div>
                                        </div>
                                    }
                                    <div className="item last-payment">
                                        <div className="label">{localized.LastPaymentApplied_Label}:</div>
                                        <div className="value">
                                            <Money value={summary.lastPayment.amount} currency={summary.lastPayment.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                    <div className="item last-payment-date">
                                        <div className="label">{localized.DateOfLastPayment_Label}:</div>
                                        <div className="value">
                                            <DateDisplay date={summary.lastPaymentDate} dateFormat={settingsList.DateDisplayFormat} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="summary-wrapper aging">
                                <div className="wrapper">
                                    <div className="heading2">{localized.AccountAging_Label} </div>
                                    {
                                        AccountSummaryConfig.childComponents.FutureAccountAging.shown === true &&                                     
                                            <div className="item">
                                            <div className="label">{localized.Future_Label}:</div>
                                            <div className="value">28-56-84</div>
                                        </div>
                                    }
                                    {
                                        AccountSummaryConfig.childComponents.CreditLimit.shown === true &&
                                        <div className="item credit-limit">
                                            <div className="label">{localized.CreditLimit_Label}:</div>
                                            <div className="value">
                                                <Money value={summary.creditLimit.amount} currency={summary.creditLimit.currency} country={selectedCustomer.address.country} />
                                            </div>
                                        </div>
                                    }
                                    <div className="item current-amount-due">
                                        <div className="label">{localized.Current_Label}:</div>
                                        <div className="value">
                                            <Money value={summary.balanceDue.amount} currency={summary.balanceDue.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                    <div className="item past-due-30">
                                        <div className="label">{localized.PastDue_Label.replace("{0}", summary.pastDueTerm1.label)}:</div>
                                        <div className="value">
                                            <Money value={summary.pastDueTerm1.amount} currency={summary.pastDueTerm1.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                    <div className="item past-due-60">
                                        <div className="label">{localized.PastDue_Label.replace("{0}" ,summary.pastDueTerm2.label)}:</div>
                                        <div className="value">
                                            <Money value={summary.pastDueTerm2.amount} currency={summary.pastDueTerm2.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                    <div className="item  past-due-90">
                                        <div className="label">{localized.PastDue_Label.replace("{0}", summary.pastDueTerm3.label)}:</div>
                                        <div className="value">
                                            <Money value={summary.pastDueTerm3.amount} currency={summary.pastDueTerm3.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                    <div className="item past-due-120">
                                        <div className="label">{localized.PastDue_Label.replace("{0}", summary.pastDueTerm4.label)}:</div>
                                        <div className="value">
                                            <Money value={summary.pastDueTerm4.amount} currency={summary.pastDueTerm4.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                    <div className="item past-due-150">
                                        <div className="label">{localized.PastDue_Label.replace("{0}", summary.pastDueTerm5.label)}:</div>
                                        <div className="value">
                                            <Money value={summary.pastDueTerm5.amount} currency={summary.pastDueTerm5.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                    <div className="item past-due-180">
                                        <div className="label">{localized.PastDue_Label.replace("{0}", summary.pastDueTerm6.label)}:</div>
                                        <div className="value">
                                            <Money value={summary.pastDueTerm6.amount} currency={summary.pastDueTerm6.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                    <div className="item past-due-over-180">
                                        <div className="label">{localized.PastDue_Label.replace("{0}", summary.pastDueTerm7.label)}:</div>
                                        <div className="value">
                                            <Money value={summary.pastDueTerm7.amount} currency={summary.pastDueTerm7.currency} country={selectedCustomer.address.country} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>

                    }
                </React.Fragment>
            }
        </div>
    )

}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

AccountSummary.propTypes = {
    selectedCustomer: PropTypes.object,
}

export default AccountSummary;