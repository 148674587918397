import React, { useState, useEffect, useRef } from 'react'
import { useCustomers } from '../../hooks/CustomerContext';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import SalesHistoryReportFilters from './SalesHistoryReportFilters';
import CustomerService from '../../services/Customer';
import Loading from '../Loading';
import Money from '../Money';

function SalesHistoryReport(props) {
    const { currentCustomer: selectedCustomer } = useCustomers();
    const salesHistoryConfig = useSalesHistoryConfig();
    const localized = salesHistoryConfig.labels;
    const [fetching, setFetching] = useState(false);
    const [salesData, setSalesData] = useState([]);
    const [grandTotalArray, setGrandTotalArray] = useState([]);

    const fetchSalesByCategory = (fromFullDate, toFullDate, customer, territoryCode, companyCode) => {
        if (selectedCustomer) {
            setGrandTotalArray([]);
            setSalesData([]);
            setFetching(true);
            CustomerService.getSalesByCategory(fromFullDate, toFullDate, territoryCode, companyCode, customer, loadingData);
        }
    };

    const loadingData = (response) => {
        if (response) {
            if (response?.length > 0) {
                setSalesData(response)
                calculateGrandTotals(response);
            }
        }
        setFetching(false);
    };

    const calculateGrandTotals = (response) => {
        let calculatedGrandTotalArray = [];
        response.forEach(item => {
            if (calculatedGrandTotalArray.findIndex(e => e.currencyCode == item.currencyCode) === -1) {
                calculatedGrandTotalArray.push({ "currencyCode": item.currencyCode, "total": item.totalSales, "countryCode": item.countryCode })
            }
            else {
                let addIndex = calculatedGrandTotalArray.findIndex(e => e.currencyCode == item.currencyCode);
                calculatedGrandTotalArray[addIndex].total += item.totalSales;
            }
        });
        setGrandTotalArray(calculatedGrandTotalArray);

    };

    const renderSalesHistoryReportFilters = () => {
        return (
            <SalesHistoryReportFilters fetchSalesReport= {fetchSalesByCategory}/>);
    };

    const renderSalesHistoryHeaders = () => {
        return (
            <>
                <div className="display-2 d-flex justify-content-start" style={{ marginBottom: '50px' }}>
                    <b className='title'>{localized.SalesHistoryReport}</b>
                </div>
                <div className="seperator"></div>
            </>
        );
    };

    const renderSalesHistoryGrid = () =>{
        return (
            <>
            {renderSalesHistoryGridHeader()}
            {
                fetching &&
                <Loading type="brand" />
            }
            {
                !fetching && (salesData && salesData.length === 0) &&
                <div data-cy="report-details-no-results" className="details no-result">
                    {localized.NoSalesHistoryFound}
                </div>
            }
            {!fetching && (salesData && salesData.length > 0) && renderSalesHistoryData()}
            {renderSalesHistoryGridFooter()}
            </>
        );
    };

    const renderSalesHistoryGridHeader = () => {
        return (
            <div className="br-history-header">
                <div className="br-item-name">
                    <div className="label">{localized.CustomerShip}</div>
                </div>
                <div className="br-item-name">
                    <div className="label">{localized.Sales}</div>
                    <div className="fele-content">
                       {localized.HistoryPriceTax}
                    </div>
                </div>
            </div>
        );
    };

    const renderSalesHistoryData = () => {
        return (
            <div data-cy="history" className="br-history">
                {salesData.map((item, key) => renderSalesHistoryRowData(item,key))}
            </div>
        );
    };

    const renderSalesHistoryRowData = (sales, index) => {
        return (
            <div className="br-details" key={index}>
                <div className="br-item-name">
                    <div className="fele-content">{`${sales.customerName} [${sales.addressBookNumber}]`}</div>
                </div>
                <div className="br-item-description">
                    <div className="fele-content">
                        <Money value={sales.totalSales} currency={sales.currencyCode} country={sales.countryCode} />
                    </div>
                </div>
            </div>
        )
    };

    const renderSalesHistoryGridFooter = () => {
        return (
            <div>
                {grandTotalArray.map((item, key) => renderGrandTotalSalesRowData(item, key))}
            </div>
        );
    };

    const renderGrandTotalSalesRowData = (sales, index) => {
        return (
            <div className="br-history-header" key={index }>
                <div className="br-item-name" style={{ textAlign: 'right', paddingRight: '2%' }}>
                    <div className="label">{sales.currencyCode} {localized.TotalSales}: </div>
                </div>
                <div className="br-item-description">
                    <div className="fele-content">
                        <Money value={sales.total} currency={sales.currencyCode} country={sales.countryCode} />
                    </div>
                </div>
            </div>
        );
    }
    return (
    <div data-cy="report-order-history" className="fele-order-history">
        {renderSalesHistoryHeaders()}
        {renderSalesHistoryReportFilters()}
        {renderSalesHistoryGrid()}
    </div>
    )
}

export default SalesHistoryReport;