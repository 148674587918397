import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import UserService from '../../services/UserService';
import StorefrontService from '../../services/Storefront';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import User from './User';
import Details from './Details';
import Create from './Create';
import Clone from './Clone';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import {withUserConfig } from '../../hooks/UserConfigContext'; 
import ImportCustomers from './ImportCustomers';
import SubmitModal from '../shared/SubmitModal';
import Notifications from '../../services/Notifications';

export class Users extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            detailView: false,
            createView: false,
            importView: false,
            selectedStorefront: this.props.currentUser.storefrontNumber,
            personas: [],
            users: this.props.users,
            selectedUserId: null,
            updateUsersToImport: [],
            validFileName:null,
            parsedCustomers: [],
            addOnly: true
        }
    }

    async componentDidMount() {
        this.mounted = true;
        await this.props.userActions.GetFilteredUsers();
        await this.props.userActions.ReceivedCurrentUser(UserService.current);
        await this.fetchPersonaList();
        let users = await UserService.fetchFilteredUsers();
        this.setState({ users: users })
    }

    setAddOnly = async (event) => {
        await this.setState({addOnly: !this.state.addOnly})
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    showDetailView = () => {
        this.setMountedState({ detailView: true, createView: false, importView: false });
    }

    loadBusinessUnits = (data) => {
        if (data) {
            this.setState({ users: data })
        }
    }

    hideDetailView = () => {
        this.setMountedState({ detailView: false });
    }

    showCreateView = () => {
        this.setMountedState({ createView: true, detailView: false, importView: false });
    }
    showImportView = () => {
        this.setMountedState({ importView: true, detailView: false, createView: false });
    }
    hideImportView = () => {
        this.setMountedState({ importView: false });
        this.setState({ updateUsersToImport: [], parsedCustomers: [], validFileName: null, addOnly:true });
    }

    handleImportSave = async () => {
        if (this.state.updateUsersToImport.length === 0) {
            Notifications.error(this.props.UserConfig.labels.NoUsersSelected);
        }
        if (this.state.parsedCustomers.length === 0) {
            Notifications.error(this.props.UserConfig.labels.NoValidCustomers);
        }
        else {
            await UserService.mergeCustomersFromCSV(this.state.parsedCustomers, this.state.updateUsersToImport, this.state.addOnly);
            Notifications.message(this.props.UserConfig.labels.ImportSuccessMessage);
            this.hideImportView();
        }
        
    }

    hideCreateView = (users) => {
        if (users) { this.props.userActions.receivedFilteredUsers(users); }
        this.setMountedState({ createView: false, selectedUser: -1 });
    }

    setSelectedStorefront = (storefrontNumber) => {
        this.setState({ selectedStorefront: storefrontNumber });
        this.fetchPersonaList(storefrontNumber);
    }

    setParsedCustomers = (customers) => {
        this.setState({ parsedCustomers: customers });
    }

    setValidFileName = (validFileName) => {
        this.setState({ validFileName: validFileName });
    }
    setUsersToImport = async (users) => {
        await this.setState({ updateUsersToImport: users });
    }

    fetchPersonaList =  async (storefrontNumber) => {
        if (storefrontNumber !== undefined) {
             await StorefrontService.getPersonas(storefrontNumber).then((res) => {
                // populate persona list
                this.setState({ personas: res });
                console.log(res);
            });
        }
    }

    setSelectedUser = (user) => {
        this.setMountedState({ detailView: false },
            () => {
                this.props.userActions.setSelectedUser(user);
                this.setMountedState({ selectedUserId: user.id }, this.showDetailView());
            });
    }

    closeSlider = () => {
        this.state.detailView && !this.state.createView ? this.hideDetailView() : this.hideCreateView();
    }

    renderAddUserButton = (localized) => {
        return (
            <div className="new-user" style={{ display: "flex" }} >
                <div className="add-import-button-wrapper" >
                    <button className="button" onClick={this.showCreateView}>+ {localized.ButtonText_AddUser}</button>
                </div>
                {this.renderImportCustomersButton()}
            </div>
        );
    }

    renderImportCustomersButton = () => {
        return (
            <div className="import-customers">
                <div className="add-import-button-wrapper" style={{ paddingLeft:"1rem" }}>
                    <button className="button" onClick={this.showImportView}>{this.props.UserConfig.labels.ImportCustomers}</button>
                 </div>
            </div>
        );
    }


    renderUserList = () => {
        return (
            this.props.users.map((user, key) => {
                return (
                    <div key={key} className="action" onClick={() => this.setSelectedUser(user)}>
                        <User user={user} isSelected={this.props.selectedUser && (this.props.selectedUser.id === user.id)} />
                    </div>
                );
            })
        );
    }
   
    render() {
        const localized = this.props.StorefrontConfig.labels;
        const importLabels = this.props.UserConfig.labels;
        return (
            <React.Fragment>
                {
                    this.props.users.length === 0 &&
                    <Loading type="brand" />
                }
                {
                    this.props.users &&
                    <div className="vertical-slide-wrapper">
     
                            <div className="users">    
                                
                                
                                {this.renderAddUserButton(localized)}
                                    {this.renderUserList()}
                            </div>
                            
                        <div className={`vertical-slide ${this.state.detailView || this.state.createView ? "open" : ""}`}>
                            <button className="fele-close" onClick={this.closeSlider} data-cy="close-user"></button>
                            {
                                this.state.createView &&
                                <Create currentUser={this.props.currentUser} isActive={this.state.createView} callback={this.hideCreateView} customers={this.props.customers} personaList={this.state.personas} />
                                }
                                {
                                    this.state.importView &&
                                    <SubmitModal
                                        isOpen={this.state.importView}
                                        onSubmit={this.handleImportSave}
                                        onClose={this.hideImportView}
                                        submitText={importLabels.Apply}
                                        closeText={importLabels.Cancel}
                                        >
                                            <ImportCustomers usersToImport={this.state.users} currentUser={this.props.currentUser} setUsersToImport={this.setUsersToImport} updateUsersToImport={this.state.updateUsersToImport} parsedCustomers={this.state.parsedCustomers} addonly={this.state.addOnly} setAddOnly={this.setAddOnly} setParsedCustomers = { this.setParsedCustomers } setValidFileName={this.setValidFileName} validFileName={this.state.validFileName} />
                                    </SubmitModal>
                                }
                            {
                                this.state.detailView &&
                                    <Details usersToClone={ this.state.users} currentUser={this.props.currentUser} userId={this.state.selectedUserId} personaList={this.state.personas} setSelectedStorefront={this.setSelectedStorefront} />
                            }
                        </div>
                    </div>
                }
                {
                    (!this.props.users || this.props.users.length === 0) &&
                    <div className="no-results">
                        <span className="section-header">
                            {localized.NoUsersMessage}
                        </span>
                    </div>
                }
            </React.Fragment>
        );
    }
}
Users.propTypes = {
    userActions: PropTypes.object,
    users: PropTypes.array,
    selectedUser: PropTypes.object,
    currentUser: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        users: state.user.filteredUsers,
        selectedUser: state.user.selectedUser,
        currentUser: state.user.currentUser,
        customers: state.user.customers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStorefrontConfig(withUserConfig(Users)));