import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Authorize from '../Authorize';
import * as Roles from '../../resources/UserRole';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';

export const ActiveInvoices = (props) => {
    const pastDueInvoiceData = props.invoiceData;
    const isEnglish = props.storeSettings.locale == 'en_US';
    const isPastDue = Object.values(pastDueInvoiceData).filter((x) => x.orderDate < new Date().toLocaleDateString('en-CA')).length;
    const classNameSizeTop = isEnglish ? "font-size-20" : "font-size-28";
    const classNameSizeBottom = isEnglish ? "font-size-28" : "font-size-20";
    var value = {
        
        code: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6InEtMjNmYWxldlpoaEQzaG05Q1Fia1A1TVF5VSIsImtpZCI6InEtMjNmYWxldlpoaEQzaG05Q1Fia1A1TVF5VSJ9.eyJhdWQiOiJhMmY1YmRkMC1lYjgyLTRmZDctYmYwYy0yMjE5ZGExN2M1YzUiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC85OTRjM2U4Yi1iNDI4LTQ1ZmItODY0MC01OTNlZjAzMDNmN2YvIiwiaWF0IjoxNzExOTk5ODMzLCJuYmYiOjE3MTE5OTk4MzMsImV4cCI6MTcxMjAwMzczMywiYWlvIjoiRTJOZ1lOaTBPZjc0U3laZDlaQkMvUmxyMmFLejFManVieXhvMnRKelVLWEtOa2xqMGtVQSIsImFwcGlkIjoiYTJmNWJkZDAtZWI4Mi00ZmQ3LWJmMGMtMjIxOWRhMTdjNWM1IiwiYXBwaWRhY3IiOiIxIiwiaWRwIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvOTk0YzNlOGItYjQyOC00NWZiLTg2NDAtNTkzZWYwMzAzZjdmLyIsIm9pZCI6IjE3ZmVlNmM1LTQwMzItNDgyZS1hZDlmLWM1MmJlY2MwNWIyOCIsInJoIjoiMC5BVmdBaXo1TW1TaTAtMFdHUUZrLThEQV9mOUM5OWFLQzY5ZFB2d3dpR2RvWHhjWHlBQUEuIiwic3ViIjoiMTdmZWU2YzUtNDAzMi00ODJlLWFkOWYtYzUyYmVjYzA1YjI4IiwidGlkIjoiOTk0YzNlOGItYjQyOC00NWZiLTg2NDAtNTkzZWYwMzAzZjdmIiwidXRpIjoiVW40dWZ3aWFPVU8zTFNnSTlIQ0NBQSIsInZlciI6IjEuMCJ9.weWRPjaqeswInwAK9o7-xNu4ldIRdvgsdej7kxhKgIFDBxaApz4SL1D5dYBUwAW9MuePDdUIjAR8LZ6ph9rl30dn24_T9iziocZnqzPXX3IyJXNZ9UzgYtDKGzqYcsyn766hjiDrZ8n12wbgIF9m7QxXukxRT9D7Wu3r9OULcJXtnmfr3wR_quGvZkLFbhOUmIIIjwlEqEud-Ubiexie-BUN0xZOdGd378_SfAgwmH9u2501sHmDr7zIhlNWkMFOwjeLGQxBZih6Lg9sChN37jNZiaoSGyZjhoqQVJ3x_7WO0fRmgDIHuI98OsNOrm3U9wX09BRLgr5wQNCUmR6vjA"
    };
    
    

    return (
        <>
            {props.storeSettings.settingsList.UseDashboardActiveInvoices == '1' &&
            <>
                <div className="green-box" style={{width: "400px", paddingLeft: "10px"} }>
                    <Authorize hasRoles={[Roles.HAS_PAYMENT_PORTAL_ACCESS]} displayMessage={false}>
                        <Link className="link-style" to={{ pathname: props.isFueling ? "" : "https://eip-test-wat.fele.com/", state: { value } }} target='_blank'>
                            <button style={{
                                borderRadius: '4px',
                                border: 'none',
                                background: '#7ebd42',
                                color: 'white',
                                padding: '7px',
                                margin: '10px',
                                float: 'right'
                            }} >
                             {props.dashboardLabels.Pay_Now }
                            </button>
                        </Link>
                   </Authorize>
                    <div>
                        <Link className="link-style" to={{ pathname: "/invoices", query: { days:730 } }}>
                            <div className="flex">
                                <RequestQuoteOutlinedIcon fontSize="large" style={{ color: "gray", height: "6rem", width: "7.25rem", marginTop: '10px' }} />
                                <div className="big-number">
                                    <div className="font-size-64">
                                        { pastDueInvoiceData.length }
                                    </div>
                                    <div className="label-wrapper">
                                        <div className={classNameSizeTop} style={{
                                            color: isPastDue > 0 && isEnglish ? "#118ABD" : "black", whiteSpace: "nowrap" }}>
                                            {isPastDue > 0 ? props.dashboardLabels.PAST_DUE : props.dashboardLabels.Open }
                                        </div>
                                        <div className={classNameSizeBottom} style={{ color: isPastDue > 0 && !isEnglish ? "#118ABD" : "black", whiteSpace: "nowrap" }}>
                                            {props.dashboardLabels.INVOICES}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Link>
                    </div>
                </div>
        </>
               
            }
        </>
    );
}

ActiveInvoices.propTypes = {
    orderData: PropTypes.object
};
function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
    };
}

export default connect(mapStateToProps, null)(ActiveInvoices);