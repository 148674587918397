import React from 'react';
import PropTypes from 'prop-types';
import CartItems from './CartItems';
import CartIcon from '../icons/CartIcon';
import OrderSummary from './OrderSummary';
import QuickAdd from './QuickAdd';
import MessageWarning from '../MessageWarning';
import FreightHandlingCodes from './FreightHandlingCodes';
import HeaderRequestDate from './HeaderRequestDate';
import MessageStandard from '../MessageStandard';
import PaymentTerms from './PaymentTerms';
import Loading from '../Loading';
import Authorize from '../Authorize';
import * as Roles from '../../resources/UserRole';
import UserHelper from '../../helpers/UserHelper';
import CustomerService from '../../services/Customer';
import { withCartConfig } from '../../hooks/CartConfigContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "../../modules/react-csv";
import ImportCart from './ImportCart';
import IncoTerms from './IncoTerms';

export class ItemsSummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storefrontPaymentTerms: null,
            importItemsFetching: false
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.loadDefaultPaymentTerms();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
            this.updateSelectedCustomerPaymenteTerms();
            this.updateSelectedCustomerFreightHandlingCode();
        }
    }
    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    checkCreditHold = () => {
        if (this.props.selectedCustomer && this.props.currentUser.storefront === '6400')
            return CustomerService.checkCreditHold(this.props.selectedCustomer);
        else
            return false;
    }

    loadDefaultPaymentTerms() {
        this.props.loadCustomerDefaultPaymentTerms();
    }

    updateSelectedCustomerPaymenteTerms = () => {
        if (this.props.selectedCustomer.paymentTermId && this.props.selectedCustomer.paymentInstrument) {
            this.props.paymentTermsUpdated({ "paymentTermId": this.props.selectedCustomer.paymentTermId, "paymentInstrument": this.props.selectedCustomer.paymentInstrument });
        }
    }

    updateSelectedCustomerFreightHandlingCode = () => {
        this.props.freightHandlingCodeUpdate({ target: { value: "" } });
    }

    getCSVData = () => {
        return [];
    }

    getCSVHeaders = () => {
        const headers = [
            { label: this.props.localized.ItemNumber, key: "sku" },
            { label: this.props.localized.QuantityLabel, key: "qty" }
        ];
        return headers;
    }


    setImportItemsFetching = (o) => {
        if (this.mounted) {
            this.setState({ importItemsFetching: o });
        }
    }

    render() {
        const { items, fetching, editingQuote } = this.props;
        let hasItems = items && items.length > 0;
        // this flag will have to be a context or redux level to work properly
        let checkPriceAvailability = UserHelper.checkPriceAvailability(this.props.selectedCustomer, this.props.currentUser.storefrontNumber);
        let creditHold = this.checkCreditHold();
        const customerWarningMessage = (this.props.selectedCustomer.isActive === false) ? this.props.localized.InActiveCustomerSelectedWarningMessage : this.props.localized.ShipToNotSelectedWarningMessage;
        return (
            <>

                {
                    this.props.CartConfig.cartConfigLoaded !== true && this.props.CartConfig.cartSettingsLoaded !== true &&
                    <Loading type="brand" />
                }
                {
                    this.props.CartConfig.cartConfigLoaded === true && this.props.CartConfig.cartSettingsLoaded === true &&
                    <div style={{padding:"2.5rem"} }>
                        {
                            creditHold &&
                            <div id="CreditHoldWarning">
                                <MessageWarning title={""} message={this.props.localized.CreditHold_Warning} />
                            </div>
                        }
                        {
                            this.props.selectedCustomer &&
                            !checkPriceAvailability &&
                            <div id="CustomerIsBillToWarning">
                                <MessageWarning
                                    title={this.props.localized.ShipToNotSelectedWarningTitle}
                                    message={customerWarningMessage}
                                />
                            </div>
                        }
                        {
                            this.props.selectedCustomer &&
                            checkPriceAvailability && // Order Header component?
                            !creditHold &&
                            this.props.CartConfig.cartSettingsLoaded &&
                            <React.Fragment>
                                {/*Move to order header component */}
                                <div className="fele-cart-header">
                                    <div className="d-lg-flex justify-content-between sticky-top" >
                                        <div className="d-flex justify-content-start">
                                            <div className="title">
                                                <CartIcon />
                                                <div className="heading1">
                                                    {this.props.localized.EditTitle}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.props.CartConfig.settings.ShowImportCart === true &&
                                            <Authorize hasRoles={[Roles.BULK_IMPORT]} displayMessage={false}>
                                                <div className="align-self-lg-end align-self-md-start d-md-inline d-xl-flex"
                                                    style={{ backgroundColor: this.props.CartConfig.childComponents.NewFeature.shown === true ? "rgba(254, 255, 183, 1)" : "none" }}>
                                                    {
                                                        <>
                                                            {
                                                                this.props.CartConfig.childComponents.NewFeature.shown === true &&
                                                                <div className="label text-nowrap px-3" style={{ color: '#FF8300', padding: '10px' }}>
                                                                    {this.props.localized.New}
                                                                </div>
                                                            }
                                                            <div className="export-button button-wrapper px-3" >
                                                                            <CSVLink id="import-csv-link" separator={this.props.localized.ExcelSeparator}  data={this.getCSVData()} filename={"Download Template"}
                                                                    headers={this.getCSVHeaders()} className="button button-alt text-nowrap px-5" style={{
                                                                        paddingTop: '5px',
                                                                        textDecoration: 'none'
                                                                    }}>
                                                                    <FontAwesomeIcon icon={faFileExcel} /> {this.props.localized.DownloadExcelTemplate}
                                                                </CSVLink>
                                                            </div>
                                                            <div className="button-wrapper text-nowrap px-1" >
                                                                <ImportCart localized={this.props.localized} currentUser={this.props.currentUser} cartConfig={this.props.CartConfig} selectedCustomer={this.props.selectedCustomer}
                                                                    setImportItemsFetching={this.setImportItemsFetching} cartItems={items}
                                                                />
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </Authorize>
                                        }
                                    </div>

                                            <div className="row">
                                                <div className="col-12 justify-content-end d-flex gy-2">
                                                    <p className="text-right">  {this.props.localized.DisclaimerText}</p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.props.selectedCustomer &&
                                    checkPriceAvailability &&
                                    !creditHold &&
                                    this.props.CartConfig.cartSettingsLoaded &&
                                    <React.Fragment>
                                        <div className="fele-my-order-settings">
                                            <FreightHandlingCodes localized={this.props.localized} isFetching={this.props.fetching} handleChange={this.props.freightHandlingCodeUpdate} freightHandlingCode={this.props.freightHandlingCode} currentUser={this.props.currentUser} />
                                            <PaymentTerms isFetching={this.props.fetching} editingQuote={editingQuote} selectedCustomer={this.props.selectedCustomer} currentUser={this.props.currentUser}
                                                updatePaymentTerms={this.props.paymentTermsUpdated}
                                                paymentTermId={this.props.paymentTermId} paymentInstrument={this.props.paymentInstrument} storefrontPaymentTerms={this.props.CartConfig.storefrontPaymentTerms}
                                                isCashPayment={this.props.isCashPayment} />
                                            <HeaderRequestDate updateDate={this.props.updateDate} requestDate={this.props.requestDate} dateFormat={this.props.dateFormat} />
                                            {
                                                !this.props.repriceMessageDisplayed &&
                                                <>
                                                    <IncoTerms localized={this.props.localized} termsAlignStyling={true} subTotal={this.props.subTotal} seprateDiv={false} />
                                                </>
                                            }
                                        </div>
                                        <div className="d-lg-flex justify-content-between sticky-top" style={{ top: "10.7rem", backgroundColor: 'white', borderBottom: '0.1rem solid #E8E8E8', paddingBottom: '12px' }}>
                                            <div>
                                                <QuickAdd className="d-inline-block flex-nowrap" currentUser={this.props.currentUser}
                                                    addItemToCart={this.props.addItemToCart} localized={this.props.localized} selectedCustomer={this.props.selectedCustomer} cartLines={this.props.items.length} />
                                            </div>
                                            <div className="align-self-end d-flex" style={{ marginTop: "1rem" }}>
                                                {
                                                    this.props.repriceMessageDisplayed &&
                                                    <div className="reprice-button">
                                                        <div className="button-wrapper">
                                                            <button data-cy="reprice-order" className="button reprice-cart-button text-nowrap px-5" onClick={this.props.repriceCart}>{this.props.localized.ButtonText_Reprice}</button>
                                                        </div>
                                                    </div>

                                                }
                                                {!this.props.repriceMessageDisplayed &&
                                                    <>
                                                                <div className="align-self-lg-end align-self-md-start d-md-inline d-xl-flex">
                                                            <div className="d-block d-lg-flex quote-button">
                                                                {
                                                                    !editingQuote &&
                                                                    <Authorize hasRoles={[Roles.PLACE_QUOTES]} displayMessage={false}>
                                                                        <div className="button-wrapper" style={{ marginTop: '-10px', marginRight: '14px' }}>
                                                                            <button data-cy="review-order" className="button place-quote-button text-nowrap px-5" onClick={this.props.reviewQuote}>{this.props.localized.ButtonText_ReviewQuote}</button>
                                                                        </div>
                                                                    </Authorize>
                                                                }
                                                            </div>
                                                            <div className="button-wrapper">
                                                                {
                                                                    !editingQuote && !this.props.repriceMessageDisplayed &&
                                                                    <Authorize hasRoles={[Roles.PLACE_ORDERS]} displayMessage={false}>
                                                                        <button data-cy="review-order" className="button review-button text-nowrap px-5" onClick={this.props.reviewIfItemsAvailable}>{this.props.localized.ButtonText_ReviewOrder}</button>
                                                                    </Authorize>
                                                                }
                                                                {
                                                                    editingQuote && !this.props.repriceMessageDisplayed &&
                                                                    <button data-cy="review-order" className="button review-button text-nowrap px-5" onClick={this.props.reviewIfItemsAvailable}>{this.props.localized.ButtonText_ReviewQuote}</button>
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {
                            this.state.importItemsFetching === true &&
                            <Loading type="brand" />
                        }
                        {
                            ((items && items.length === 0) || creditHold) &&
                            <div className="fele-empty-cart">
                                <MessageStandard
                                    icon={<CartIcon hideCount={true} />}
                                    line1={this.props.localized.CartEmptyMessage}
                                />
                            </div>
                        }
                        {
                            this.props.selectedCustomer &&
                            checkPriceAvailability &&
                            hasItems &&
                            !creditHold &&
                            <React.Fragment>
                                {
                                    !fetching && !this.state.importItemsFetching &&
                                    <CartItems
                                        key={items.length}
                                        selectedCustomer={this.props.selectedCustomer}
                                        dateFormat={this.props.dateFormat}
                                        currentUser={this.props.currentUser}
                                        items={this.props.items}
                                        updateLineItemQuantity={this.props.updateLineItemQuantity}
                                        removeItem={this.props.removeItem}
                                        updateCartItem={this.props.updateCartItem}
                                        components={this.props.components}
                                        pcDiscountCodes={this.props.CartConfig.pcDiscountCodes}
                                        requestDate={this.props.requestDate} />
                                }
                                {
                                    fetching &&
                                    <Loading type="brand" />
                                }
                                <OrderSummary localized={this.props.localized} subTotal={this.props.subTotal} currency={this.props.currency} fetching={fetching} callback={this.props.nextStep} selectedCustomer={this.props.selectedCustomer} />
                            </React.Fragment>
                        }
                    </div>
                }
            </>
        )
    }
}

ItemsSummary.propTypes = {
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
    items: PropTypes.array,
    freightHandlingCode: PropTypes.string,
    itemCount: PropTypes.number,
    paymentInstrument: PropTypes.string,
    paymentTermId: PropTypes.string,
    dateFormat: PropTypes.string,
    repriceMessageDisplayed: PropTypes.bool,
    fetching: PropTypes.bool,
    editingQuote: PropTypes.bool,
    subTotal: PropTypes.number,
    currency: PropTypes.string,
    requestDate: PropTypes.object,
    updateLineItemQuantity: PropTypes.func,
    removeItem: PropTypes.func,
    updateCartItem: PropTypes.func,
    addItemToCart: PropTypes.func,
    updateOrderHeader: PropTypes.func,
    paymentTermsUpdated: PropTypes.func,
    freightHandlingCodeUpdate: PropTypes.func,
    updateDate: PropTypes.func,
    reviewQuote: PropTypes.func,
    reviewIfItemsAvailable: PropTypes.func,
    repriceCart: PropTypes.func,
    localized: PropTypes.object,
    loadCustomerDefaultPaymentTerms: PropTypes.func
};

export default withCartConfig(ItemsSummary);