import React, { useState } from 'react';
import Money from '../Money';
import { withRouter } from "react-router-dom";
import { useSelector } from 'react-redux';
import OrderService from '../../services/OrderService';
import CompletePricing from '../orders/CompletePricing';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import { useStorefrontSettings } from '../../hooks/StorefrontSettingsContext';

const QuoteItem = props => {
    const salesHistoryConfig = useSalesHistoryConfig();
    const localized = salesHistoryConfig.labels;
    const childComponents = salesHistoryConfig.childComponents;
    const store = props.store ? props.store : useSelector((state) => state);
    const [showCompletePricing, setShowCompletePricing] = useState(false);
    const storefront = store.user.currentUser.storefront;
    const storefrontConfig = useStorefrontSettings();
    const [completeLinePricingData, setCompleteLinePricingData] = useState([{ DescAdjName: "", FactorValueNumeric: 0, UnitPrice: 0, Override: false, BasePrice: false }]);
    let item = props.item;
    if (!item) return null

    const handleClickInner = e => {
        e.stopPropagation();
        return OrderService.getCompleteLinePricing(item.orderId, item.orderType, item.orderCompany, item.lineNumber, storefrontConfig.locale).then((data) => {
            if (data) {
                setCompleteLinePricingData(data);
                setShowCompletePricing(true);
            }
        });
    }

    return (
        <div className="wrapper">
            <div className="item">
                <div className="left-side-quote">
                    <div className="left-info-items">
                        <div>
                            <div className="part-name">
                                <div className="fele-content">{item.productName}</div>
                            </div>
                            <div className="part-number">
                                <div className="fele-content">{item.productSku}</div>
                            </div>
                            <div className="part-description">
                                <div className="fele-content">{item.productDescription}{item.productGeneralDescription && item.productGeneralDescription.length > 0 ? "," + item.productGeneralDescription : ""}</div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="left-info-items">
                        <div className="quantity">
                            <div className="label">{localized.QtyLabel}:</div>
                            <div className="fele-content">{item.quantity}</div>
                        </div>
                        <div className="each">
                            <div className="label">{localized.UnitPrice}: </div>
                            <Money value={item.unitPrice_Amount} currency={item.price_Currency} country={props.selectedCustomer.address.country} />
                        </div>
                        <div className="total">
                            <div className="label">{localized.Total}: </div>
                            <Money value={item.price_Amount} currency={item.price_Currency} country={props.selectedCustomer.address.country} />
                        </div>
                        <div>
                        {childComponents.LinePaymentTerms.shown &&
                            <div className="paymentTerms">
                                <div className="label">{localized.PaymentTermsLabel}:</div>
                                <div className="fele-content">{item.paymentTerms} - {item.paymentTermsDescription}</div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="right-side">
                    {childComponents.LinePaymentTerms.shown &&
                            <div className="button-wrapper pricing-button">
                                <button className="button button-secondary button-padding pricing-button-text" onClick={handleClickInner}>
                                    {localized.ViewCompletePricingDetailsLabel}
                                </button>
                            </div>
                        }
                    
                </div>
            </div>
            {showCompletePricing &&
            <CompletePricing
                isOpen={showCompletePricing}
                onClose={() => setShowCompletePricing(false)}
                completeLinePricingData={completeLinePricingData}>
            </CompletePricing>
            }
        </div>
    );
}
export default withRouter(QuoteItem);
