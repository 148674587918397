import React from 'react';
import PropTypes from 'prop-types';
import Money from '../Money';
import Availability from '../products/Availability';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import QuantityInput from './QuantityInput';
import DeliveryDiscount from './DeliveryDiscount';
import AngleDown from '../icons/AngleDown';
import _ from 'lodash';
import { useCartConfig } from '../../hooks/CartConfigContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const CartItem = props => {
    const cartConfig = useCartConfig();
    const item = props.item;
    if (!item | _.isEmpty(item)) return null;

    const localized = cartConfig.labels;

    const remove = (e) => {
        props.removeItem(e.target.value);
    }

    const { availability, quantity, netPrice, netPriceCurrency, id, product, quantityAvailable } = item;

    const productAvailability = (stockingType, availability) => {
        if (stockingType === "U" && availability === "backOrdered")
            return localized.Unavailable;

        // This check is for Australia showing available for manufacturing items will be removed in future
        if (item.branchStockingType === "M" && cartConfig.settings.ShowAvailable === true)
            return localized.Available;

        return availability;
    }

    return (
        <div className="cart-item">
            <div className="row">
                <div className="col">
                    <div className="row pt-md-2 pb-md-2">
                        <div className="col-md-8">
                            <div className="part-name">
                                <div className="fele-content">{product.name}</div>
                            </div>
                            <div className="long-description">
                                <div className="fele-content">{product.description}</div>
                            </div>
                        </div>
                        <div className="col-md-4 d-none d-sm-none d-md-block">
                            <div className="cart-price text-end">
                                <div className="fele-content">
                                    <div>
                                        <Money key={netPrice} value={quantity * netPrice} currency={netPriceCurrency} country={props.selectedCustomer.address.country} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-3">
                        <div className="col">
                            <div className="cart-part-number">
                                <div className="label">{localized.ItemNumberLabel}:</div> <div className="fele-content mr-3">{product.sku}</div>
                                <div className="cart-availability d-sm-block d-md-inline-block">
                                    <div className="fele-content" style={{padding: "10px"} }>
                                        <Availability availability={productAvailability(product.stockingType, availability)} />
                                    </div>
                                </div>
                                <div className="fele-content">
                                    {
                                        cartConfig.settings.ShowUseUpQuantity === true && product.stockingType === "U" &&  availability !== "Unavailable" && quantity.quantityAvailable > 0 &&
                                        <div style={{ whiteSpace: 'nowrap', marginTop: '2px' }}>
                                            <FontAwesomeIcon icon={faExclamationTriangle} color="orange" /> {localized.QuantityAvailableLabel?.replace("{0}", quantityAvailable)}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="cart-price-each">
                                <div className="fele-content">
                                    <div>
                                        <div className="label">{localized.PriceEachLabel}:&nbsp;</div>
                                        <Money value={netPrice} currency={netPriceCurrency} country={props.selectedCustomer.address.country} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col text-end d-none d-sm-none d-md-block">
                            <QuantityInput localized={localized} item={item} updateCartItem={props.updateCartItem} updateLineItemQuantity={props.updateLineItemQuantity} />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {/* Mobile Quantity, Price and Remove */}
                <div className="d-flex flex-row align-items-center mt-2 mb-3 d-md-none">
                    <div className="mr-3">
                        <QuantityInput localized={localized} item={item} updateCartItem={props.updateCartItem} updateLineItemQuantity={props.updateLineItemQuantity} />
                    </div>
                    <div className="flex-fill mr-3">
                        <div className="cart-price text-end">
                            <div className="fele-content">
                                <div>
                                    <Money key={netPrice} value={quantity * netPrice} currency={netPriceCurrency} country={props.selectedCustomer.address.country} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-end d-md-none">
                        <div className="remove button-wrapper small">
                            <button className="remove link" value={id} onClick={remove} alt={localized.ButtonText_Remove}></button>
                        </div>
                    </div>
                </div>

                <div className="flex-row flex-wrap flex-end cart-bottom-row d-none d-sm-none d-md-flex">
                    <DeliveryDiscount item={item} updateCartItem={props.updateCartItem} currentUser={props.currentUser} dateFormat={props.dateFormat} pCDiscountCodes={props.pCDiscountCodes} requestDate={props.requestDate} />
                    <div className="flex-fill text-end d-none d-sm-none d-md-block mt-4">
                        <div className="remove button-wrapper small">
                            <button className="remove link" value={id} onClick={remove} alt={localized.ButtonText_Remove}></button>
                        </div>
                    </div>
                </div>

                {/* collapse delivery and discounts for mobile */}
                {
                    cartConfig.childComponents.LineRequestDate.shown === true &&
                    <Accordion className="d-sm-block d-md-none">
                        <Card>
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                                {localized.DeliveryDateDiscountsLabel}
                                <AngleDown />
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <DeliveryDiscount item={item} updateCartItem={props.updateCartItem} currentUser={props.currentUser} dateFormat={props.dateFormat} pCDiscountCodes={props.pCDiscountCodes} requestDate={props.requestDate} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                }

            </div>
        </div>

    )
}

CartItem.propTypes = {
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
    dateFormat: PropTypes.string,
    item: PropTypes.object,
    itemId: PropTypes.string,
    updateLineItemQuantity: PropTypes.func,
    removeItem: PropTypes.func,
    updateCartItem: PropTypes.func,
    pCDiscountCodes: PropTypes.object,
    requestDate: PropTypes.object
};
export default CartItem;