export default class UserHelper {

    static hasRoles(roles, user) {
        const rolesList = user.roles;
        return roles.some(function (r) {
            return rolesList.some(function (element) {
                return (r.toLowerCase() === element.toLowerCase());
            });
        });
    }

    static checkPriceAvailability(selectedCustomer, storefront) {
        let isParent = (selectedCustomer.addressType === "Parent" || selectedCustomer.addressType === "Bill_To");
        let canCheck = false;
        if (storefront === '9000')
            canCheck = (selectedCustomer.billingType !== "B" && !isParent); //Check that the customer is NOT Bill To or Parent
        else
            canCheck = selectedCustomer.billingType !== "B";

        return canCheck && (selectedCustomer.isActive !== false);
    }

    static checkSpecialCharacters(value) {
        const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (regex.test(value)) return true;
    }

    static checkCustomerAddressType(addressType) {
        if (this.isShipToCustomer(addressType) || addressType === "Both") {
            return true;
        }
        return false;
    }

    static isValidEmail(email) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regex.test(email)) return true;
    }

    static isShipToCustomer(addressType) {
        if (addressType === "Ship_To") {
            return true;
        }
        return false;
    }

    static isValidItemNumber(value) {
        const regex = /[!@#$%^&*()+\=\[\]{};':"\\|,<>\/?]+/;
        if (regex.test(value)) return true;
    }
}