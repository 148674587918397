import Api from './Api';

class Storefront {
    storefronts = {};

    fetchStorefronts = () => {
        return Api.fetch(`/api/Administrative/storefronts`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getStorefrontSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/Storefront/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getStorefrontOrderQuoteStatuses = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/OrderQuoteStatuses/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getDashboardSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/DashboardSettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getCartSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/CartSettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getSalesHistorySettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/SalesHistorySettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getInvoiceSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/InvoiceSettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getProductSettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/ProductSettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getAccountSummarySettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/AccountSummarySettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getWarrantySettings = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/WarrantySettings/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    getPersonas = (storefrontNumber) => {
        return Api.fetch(`/api/Settings/Personas/${storefrontNumber}`)
            .then((res) => { return res; })
            .catch(this.handleError);
    }

    handleError = (e) => {
        console.log("Failed to load storefronts.");
        console.log(e);
    }
}

export default new Storefront();