import LocalizedStrings from 'react-localization';
import Localization from '../services/Localization';

class WelcomeStrings {
    localized = new LocalizedStrings({
        en: {
            AdditionalComments: "Additional Comments",
            CustomerMessage: "A Customer Success Manager will be in contact with you shortly",
            CustomerNumber: "Customer Number (Optional)",
            FEDirectLink: "Learn more about FE Direct today",
            LearningFEDirectURL: "https://franklintech.exceedlms.com/student/path/778846-how-to-use-fe-direct",
            SelectStoreFrontQuestion: "Please select your storefront and enter your customer number below",
            Submit: "Submit",
            SuccessMessage: "Your message has been sent",
            ThankYouSigningUpMessage: "Thank you for signing up for FE Direct!",
            WhereDoYouDoBusinessQuestion: "Where do you do business with Franklin Electric?"
        },

        pt: {
            AdditionalComments: "Comentários adicionais",
            CustomerMessage: "Por favor aguarde, entraremos em contato. Obrigado!",
            CustomerNumber: "Número do Cliente",
            FEDirectLink: "Saiba mais sobre FE Direct hoje!",
            LearningFEDirectURL: "https://franklintech.exceedlms.com/student/path/778846-how-to-use-fe-direct",
            SelectStoreFrontQuestion: "Insira seu número de cliente e selecione seu país",
            Submit: "Enviar",
            SuccessMessage: "Sua mensagem foi enviada",
            ThankYouSigningUpMessage: "Obrigado por se inscrever no FE Direct!",
            WhereDoYouDoBusinessQuestion: "Onde você faz negócios com a Franklin Electric?"
        },

        es: {
            AdditionalComments: "Comentarios Adicionales",
            CustomerMessage: "AUn representante de servicio al cliente se pondrá en contacto con usted en breve",
            CustomerNumber: "Número do Cliente",
            FEDirectLink: "¡Aprenda más sobre FE Direct hoy!",
            LearningFEDirectURL: "https://franklintech.exceedlms.com/student/path/778846-how-to-use-fe-direct",
            SelectStoreFrontQuestionMessage: "Por favor ingrese su número de cliente y seleccione su país a continuación",
            Submit: "Enviar",
            SuccessMessage: "Su mensaje ha sido enviado",
            ThankYouSigningUpMessage: "¡Gracias por registrarse en FE Direct!",
            WhereDoYouDoBusinessQuestion: "¿Dónde hace negocios con Franklin Electric?"
        },

        it: {
            AdditionalComments: "Additional Comments",
            CustomerMessage: "A Customer Success Manager will be in contact with you shortly",
            CustomerNumber: "Customer Number (Optional)",
            FEDirectLink: "Learn more about FE Direct today",
            LearningFEDirectURL: "https://franklintech.exceedlms.com/student/path/778846-how-to-use-fe-direct",
            SelectStoreFrontQuestion: "Please select your storefront and enter your customer number below",
            Submit: "Submit",
            SuccessMessage: "Your message has been sent",
            ThankYouSigningUpMessage: "Thank you for signing up for FE Direct!",
            WhereDoYouDoBusinessQuestion: "Where do you do business with Franklin Electric?"
        },
                          
        setLanguage: LocalizedStrings.setLanguage,
    });

    localize() {
        this.localized.setLanguage(Localization.language);
        Localization.subscribe(() => this.localized.setLanguage(Localization.language));
        return this.localized;
    }
}

export default new WelcomeStrings();
