import React from 'react';
import Strings from './Welcome.Strings';
import Api from '../services/Api';
import Localization from '../services/Localization';
import LanguageStrings from './LanguageSelect.Strings';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.localized = Strings.localize();
        this.languageLocalized = LanguageStrings.localize();
        this.state = {
            storefronts: [],
            customerNumber: "",
            comments: "",
            email: props.currentUser.email,
            isSubmitted: false,
            selectedStorefront: {
            }
        };
    }

    componentDidMount() {
        this.fetchStorefronts();
        this.updateLanguage();
        Localization.subscribe(this.updateLanguage);
    }

    fetchStorefronts = () => {
        return Api.fetch(`/api/Administrative/storefronts`)
            .then(this.loadStorefronts);
    }

    handleLanguageChange = (e) => {
        Localization.set(e.target.value);
    }

    updateLanguage = () => {
        this.setState({ language: Localization.language });
    }

    loadStorefronts = (response) => {
        if (response) {
            const filteredStorefronts = [];
            response.forEach((s) => {
                if (s.number !== '00000' && s.number !== '05000') {
                    filteredStorefronts.push({ number: s.number, name: s.displayName });
                }
            });
            filteredStorefronts.sort((a, b) => (a.name > b.name) ? 1 : -1)
            this.setState({ storefronts: filteredStorefronts, selectedStorefront: filteredStorefronts.length>0 ? filteredStorefronts[0] : {} });
        }
    }

    setStoreFront = (e) => {
        var index = e.target.options.selectedIndex;
        var storeFront = e.target.options[index].text;
        this.setState({ selectedStorefront: { name: storeFront, number: e.target.value } })
    }

    sendEmail = () => {
        const userInfoData = {
            StoreFront: this.state.selectedStorefront.name,
            StorefrontNumber: this.state.selectedStorefront.number,
            Email: this.state.email,
            CustomerNumber: this.state.customerNumber,
            AdditionalComments: this.state.comments           
        };
        Api.fetch("api/user/SendSignedUpEmail", { body: JSON.stringify(userInfoData), method: 'POST' })
            .catch(this.handleError);
    }

    submit = (e) => {
        this.sendEmail();
        this.setState({ isSubmitted: true });
    }

    render() {
        const { storefronts, language, isSubmitted } = this.state;
        return (

            <React.Fragment>
                <div className="fele-business-select" style={{ marginRight: "5rem", fontSize: "2rem!important" }}>
                    <div className="label">{this.languageLocalized.SelectLanguage}</div>
                    <div className="select-wrapper">
                        <select value={language} onChange={this.handleLanguageChange}>
                            <option value="en">English</option>
                            <option value="pt">Português</option>
                            <option value="es">Español</option>
                            <option value="it">Italiano</option>
                        </select>
                    </div>
                </div>
                {
                    isSubmitted &&
                    <div className="fele-welcome">
                        <div className="fele-content heading2"> {this.localized.SuccessMessage}</div>
                        <div className="fele-content heading3">{this.localized.CustomerMessage}</div>
                        <div className="button-wrapper large">
                            <a style={{ textDecoration: "none" }} href={this.localized.LearningFEDirectURL}>
                                <button className="button submit-order">{this.localized.FEDirectLink}</button>
                            </a>
                        </div>
                    </div>
                }
                {
                    !isSubmitted &&
                    <div className="fele-welcome">
                        <div className="fele-content heading2">{this.localized.ThankYouSigningUpMessage}</div>
                        <div className="fele-content heading3">{this.localized.SelectStoreFrontQuestion}</div>
                        <div className="fele-content heading4">{this.localized.WhereDoYouDoBusinessQuestion}</div>
                        <div className="fele-business-select">
                            <div className="select-wrapper">
                                <select onChange={this.setStoreFront}>
                                    {
                                        <React.Fragment>
                                            {
                                                Object.keys(storefronts).map((o) =>
                                                    <option key={o} value={storefronts[o].number}>{storefronts[o].name}</option>
                                                )
                                            }
                                        </React.Fragment>
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="welcome-input-text">
                            <div className="heading3"></div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <input data-cy="welcome-customer-number" type="text" placeholder={this.localized.CustomerNumber} value={this.state.customerNumber}
                                        onChange={e => this.setState({ customerNumber: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="comments-input-text">
                            <div className="heading3">{this.localized.AdditionalComments}</div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <textarea value={this.state.comments} onChange={e => this.setState({ comments: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="button-wrapper large">
                            <button className="button submit-order" onClick={this.submit}> {this.localized.Submit}</button>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}
Welcome.propTypes = {
    currentUser: PropTypes.object
}

function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser
    };
}

export default connect(
    mapStateToProps,
    null
)(Welcome);