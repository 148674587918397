import React from 'react';
import Strings from './Availability.Strings';
import Checkmark from '../icons/Checkmark';
import { withProductConfig } from '../../hooks/ProductConfigContext';

export class Availability extends React.Component {
    constructor(props) {
        super(props);

    }

    localize = (availability) => {
        const localized = this.props.productConfig.labels;
        if (availability === "Unavailable")
            return localized.Unavailable;

        if (availability === "Available")
            return localized.Available;

        if (availability === "Backordered")
            return localized.Backordered;

        if (availability === "Partially Available")
            return localized.PartiallyAvailable;

        return localized.Unknown;

    }
    render() {
        if (!this.props.availability)
            return null;

        return (
            <React.Fragment>
                {
                    !this.props.asIcon &&
                    <div className={"pill " + this.props.availability.toLowerCase()} >
                        {this.localize(this.props.availability)}
                    </div>
                }
                {
                    this.props.asIcon &&
                    <div className={`available-icon ${this.props.availability.toLowerCase()}`}>
                        {
                            this.props.availability === "Available" &&
                            <Checkmark />
                        }
                        {
                            this.props.availability === "Backordered" &&
                            <div className="icon">!</div>
                        }
                        {
                            this.props.availability === "Unavailable" &&
                            <div className="icon"></div>
                        }
                        {
                            this.props.availability === "Partially Available" &&
                            <div className="icon"></div>
                        }
                    </div>

                }
            </React.Fragment>

        );
    }
}
export default (withProductConfig(Availability))
