import React from 'react';
import DateDisplay from '../DateDisplay';
import StatusHelper from '../../helpers/StatusHelper';
import PropTypes from 'prop-types';

export default class QuoteSummary extends React.Component {
    constructor(props) {
        super(props);
        this.handlePress = this.handlePress.bind(this);
        this.quote = this.props.quote;
        this.selectedCustomer = this.props.selectedCustomer;
        this.storefrontNumber = this.props.storefrontNumber;
    }
    handlePress = () => {

        const convertQuoteToOrderData = {
            documentNumber: this.quote.orderId,
            documentCompany: this.quote.orderCompany,
            comments: this.quote.comments,
            customerPO: this.quote.referenceNumber,
            customerId: this.props.selectedCustomer || this.selectedCustomer.id,
            implementationId: this.props.implementationId
        }
        this.props.convertQuoteToOrder(convertQuoteToOrderData);
    }


    render() {
        const { localized } = this.props;
        let paymentTerms = this.props.paymentTerms;
        let convertQuoteEnabled = this.props.convertQuoteEnabled;
        if (!this.quote)
            return <div></div>

        return (
            <div>
                <div className="history-title">
                    <div className="order-number">
                        <div className="heading2">{localized.QuoteNumber}:</div>
                        <div className="content">{this.quote.orderId + " " + this.quote.orderType}</div>
                    </div>
                </div>
                <div className="quote-header">
                    <div data-cy="history-summary" className="history-summary">
                        <div className="left-side">
                            <div className="order-status">
                                <div className="label">{localized.QuoteStatus}:</div>
                                <div className="content">{StatusHelper.formatQuoteStatus(this.quote.convertedToOrder, this.quote.quoteStatus, localized)}</div>
                            </div>
                            <div className="order-date">
                                <div className="label">{localized.QuoteDate}:</div>

                                <div className="content">
                                    <DateDisplay date={this.quote.submittedOn} />
                                </div>
                            </div>
                        </div>
                        <div className="left-side">
                            <div className="currency">
                                <div className="label">{localized.Currency}:</div>
                                <div className="content"> {this.quote.currency} </div>
                            </div>
                            <div className="po-number">
                                <div className="label">{localized.PONumber}:</div>
                                <div className="content">{this.quote.referenceNumber}</div>
                            </div>
                        </div>
                        {this.storefrontNumber === '08700' &&
                            <div className="middle-side">
                                <div className="paymentTerms">
                                    <div className="label">{localized.PaymentTermsLabel}:</div>
                                    <div className="content">{paymentTerms}</div>
                                </div>
                            </div>
                        }
                        <div className="right-side">
                            {
                                this.quote && convertQuoteEnabled &&
                                <div className="button-wrapper">
                                    <button data-cy="convert-quote" id="convert-quote-details" className="button button-secondary" disabled={this.quote.convertedToOrder}
                                        onClick={this.handlePress}>
                                        {localized.ConvertToOrder}
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

QuoteSummary.propTypes = {
    convertQuoteToOrder : PropTypes.func
};