import React from 'react';
import PropTypes from 'prop-types';
import LineRequestDate from './LineRequestDate';
import PCDiscountCodes from './PCDiscountCodes';
import { withCartConfig } from '../../hooks/CartConfigContext';

export class DeliveryDiscount extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (
            <React.Fragment>
                <LineRequestDate item={this.props.item} updateCartItem={this.props.updateCartItem} dateFormat={this.props.dateFormat} />
                <PCDiscountCodes currentUser={this.props.currentUser} item={this.props.item} updateCartItem={this.props.updateCartItem}
                    pCDiscountCodes={this.props.CartConfig.pcDiscountCodes} requestDate={this.props.requestDate} localized={this.props.CartConfig.labels} />
            </React.Fragment>
        );
    }
}

DeliveryDiscount.propTypes = {
    item: PropTypes.object,
    updateCartItem: PropTypes.func,
    currentUser: PropTypes.object,
    dateFormat: PropTypes.string,
    pcDiscountCodes: PropTypes.object,
    requestDate: PropTypes.object
};

export default withCartConfig(DeliveryDiscount);