import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Strings from '../translations/ModalPopup.Strings';

export default class ModalPopup extends React.Component {
    constructor(props) {
        super(props);
        this.localized = Strings.localize();
    }

    render = () =>
        <>
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                backdrop="static"
                keyboard={false}
                centered
                animation={false}
            >
                <div className="button-wrapper">
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    {this.props.message}
                </Modal.Body>
                <Modal.Footer>
                    <div className="button-wrapper">
                        <Button variant="secondary" className="button button-secondary" onClick={this.props.onHide}>{this.localized.NoButton_Label}</Button>
                    </div>
                    <div className="button-wrapper">
                        <Button variant="primary" className="button" onClick={this.props.callback}>{ this.localized.YesButton_Label }</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
}
