import React, { useState, useEffect } from 'react';
import UserService from '../../services/UserService';
import CustomerService from '../../services/Customer';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';
import FilterSelect from '../form-controls/FilterSelect';
import Money from '../Money';
import Loading from '../Loading';

const addCustomerDropdownDescription = customers => {
    return customers.map(c => { return { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } });
}

function TwelveMonthSales(props) {
    const [territories, setTerritories] = useState([]);
    const [territory, setTerritory] = useState({});
    const [customer, setCustomer] = useState("");
    const salesHistoryConfig = useSalesHistoryConfig();
    const [customers, setCustomers] = useState(addCustomerDropdownDescription(props.user.assignedCustomers));
    const localized = salesHistoryConfig.labels;
    const [loading, setLoading] = useState(false);

    const [loaded, setLoaded] = useState(false);
    const [parentSales, setParentSales] = useState(0);
    const [shipToSales, setShipToSales] = useState(0);
    const [currency, setCurrency] = useState("");
    const [countryCode, setCountryCode] = useState("");

    useEffect(() => {
        let mounted = true;
        if (mounted) UserService.getUserSalesTerritories(props.user.id, loadTerritories);
        return () => { mounted = false; return null; }
    }, [])

    const loadTerritories = (data) => {
        if (data) {
            data = data.map(territory => { return { ...territory, dropdownDescription: `${territory.code} - ${territory.description}` } })
            setTerritories(data);
            if (data.length === 1) changeTerritory(data[0].code);
        }
    }

    const setSelectedCustomer = (e) => {
        setCustomer(e.target.value);
    }

    const loadCustomers = (newTerritory) => {
        //when territory changes, filter customers by the new territory
        if (newTerritory) {
            setCustomers(props.user.assignedCustomers.flatMap(c => {
                return c.salesTerritoryCode === newTerritory ? { ...c, dropdownDescription: `${c.externalId} - ${c.name}` } : [];
            }))
        }
        else setCustomers(addCustomerDropdownDescription(props.user.assignedCustomers));
    }

    const changeTerritory = (newTerritory) => {
        setTerritory(newTerritory);
        if (customer !== "") setCustomer({ target: { value: "" } });
        loadCustomers(newTerritory);
    }
    const territoryChange = (e) => {
        changeTerritory(JSON.parse(JSON.stringify(e.target.value)));
    }

    const loadReportData = () => {
        const selCust = customers.find((cust) => cust.id == customer);
        if (selCust) {
            var parent = selCust?.parent?.id || selCust.id;
            setLoading(true);
            CustomerService.get12MonthSales(selCust.id, parent).then(res => {
                console.log(res);
                setShipToSales(res.shipToSales);
                setParentSales(res.parentSales);
                setCurrency(res.currencyCode);
                setCountryCode(selCust.address.country);
                setLoaded(true);
                setLoading(false);
            });

        }
    }

    return (
        <div>
            <div className="display-2" style={{ marginBottom: '50px' }}><b>{salesHistoryConfig.labels['12MonthSales']}</b></div>
            <div className="flex">
                <div style={{ marginRight: '20px' }} >
                    <FilterSelect wrapperClass={'report-status'} label={localized.SelectTerritoryLabel} onChange={territoryChange} value={territory}
                        placeholder={localized.SelectTerritoryPlaceholder} options={territories} valueField={'code'} descriptionField={'dropdownDescription'} />
                </div>
                <div style={{ marginRight: '20px' }} >
                    <FilterSelect wrapperClass={'report-status'} label={localized.Customer} onChange={setSelectedCustomer} value={customer}
                        placeholder={localized.Customer} options={customers} valueField={'externalId'} descriptionField={'dropdownDescription'} />
                </div>
                <div className="button-wrapper small report-search">
                    <div className="label">&nbsp;</div>
                    {customer === "" && <button className="button search-button" disabled>{localized.Search}</button>}
                    {customer !== "" &&
                        <button data-cy="order-submit-report-search" className="button search-button button-alt" onClick={() => { loadReportData(); }}>
                            {localized.Search}
                        </button>
                    }
                </div>
            </div>
            {
                loading &&
                <Loading type="brand" />
            }
            {
                !loading &&
                <div className="flex justify-content-left">
                    <div style={{ marginTop: '80px', marginLeft: '80px' }}>
                        <div className="card text-center border-1 rounded-3 border-dark" style={{ width: '26rem', height: '14rem' }}>
                            <div className="card-body">
                                <div style={{ marginTop: '25px' }}>
                                    <h1 className="card-title"><strong>{localized.CustomerParent}</strong></h1>
                                    <p className="card-text">
                                        {!loaded && <span>-</span>}
                                        {loaded &&
                                            <Money value={parentSales} currency={currency} country={countryCode} />
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '80px', marginLeft: '80px' }}>
                        <div className="card text-center border-1 rounded-3 border-dark" style={{ width: '26rem', height: '14rem' }}>
                            <div className="card-body">
                                <div style={{ marginTop: '25px' }}>
                                    <h1 className="card-title"><strong>{localized.CustomerShipTo}</strong></h1>
                                    <p className="card-text">
                                        {!loaded && <span>-</span>}
                                        {loaded &&
                                            <Money value={shipToSales} currency={currency} country={countryCode} />
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div >);
}

export default TwelveMonthSales;