import React from 'react';
import Money from '../Money';

export default class Price extends React.Component {

    render() {
        if (!this.props.price)
            return <div className="price">-</div>;

        if (isEmpty(this.props.price))
            return <div className="price">-</div>
        return (
            <div className="price">
                <Money currency={this.props.price.currency} value={this.props.price.amount} country={this.props.country} />
            </div>
        );
    }
}

Price.defaultProps = {
    country: 'US'
}

function isEmpty(price) {
    return !(price.amount && price.currency)
}
