import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import { useWarrantyConfig } from '../../hooks/WarrantyConfigContext';

const NonFastTrackModal = props => {
    const { labels: localized } = useWarrantyConfig();
    const [claims, setClaims] = useState(props.claims.filter(item => item.fastTrack !== true).map((item, index) => { return { ...item, key: index }; }));
   
    const toggleReturnConfirmation = key => {
        const newClaims = claims;
        console.log(newClaims);
        const claim = newClaims.find(c => c.key === key);
        if (claim) {
            if (claim.returnConfirmation) {
                claim.freightCarrier = '';
                claim.accountNumber = '';
                claim.freightCarrierError = false;
                claim.accountNumberError = false;
            }
            claim.returnConfirmation = !claim.returnConfirmation;
            
            setClaims([...newClaims]);
        }
    }

    const setClaimValue = (e, fieldName) => {
        const newClaims = claims;
        const claim = newClaims.find(c => c.key == e.target.getAttribute('idx'));
        if (claim) {
            claim[fieldName] = e.target.value;
            setClaims([...newClaims]);
        }
    }

    const setFreightCarrier = e => {
        setClaimValue(e, 'freightCarrier');
    }

    const setAccountNumber = e => {
        setClaimValue(e, 'accountNumber');
    }

    const renderClaim = (claim, index) => {
        return (
            <div key={index} className="details">
                <div className="nft-modal-column" style={{ width: '20%' }} >
                    <div className="label">{'Item Number'}</div>
                    <div className="fele-content">{claim.itemNumber}</div>
                </div>
                <div className="nft-modal-column toggle" style={{ width: '20%' }} >
                    <button className={`fele-status-toggle ${claim.returnConfirmation ? "" : "inactive"} nft-toggle`}
                        onClick={() => toggleReturnConfirmation(claim.key)} >
                        <div className="switch nft-switch" ></div>
                    </button>
                    <span className="nft-toggle-label" >{(claim.returnConfirmation) ? localized.ReturnConfirmationYes : localized.ReturnConfirmationNo}</span>
                </div>
                <div className="nft-modal-column" style={{ width: '30%' }} >
                    <input idx={claim.key} type="text" onChange={setFreightCarrier} value={claim.freightCarrier}
                        disabled={!claim.returnConfirmation} className={(claim.freightCarrierError && claim.freightCarrierError == true) ? 'unavailable' : ''} />
                </div>
                <div className="nft-modal-column" style={{ width: '30%' }} >
                    <input idx={claim.key} type="text" onChange={setAccountNumber} value={claim.accountNumber}
                        disabled={!claim.returnConfirmation} className={(claim.accountNumberError && claim.accountNumberError == true) ? 'unavailable' : ''} />
                </div>
            </div>
        )
    }

    const submit = e => {
        if (validateReturnConfirmation()) {
            props.addNonFastTrackFields(claims);
        }
    }

    const validateReturnConfirmation = () => {
        let validData = true;
        let newClaims = claims;
        newClaims.forEach((claim, index) => {
            if (claim.returnConfirmation) {
                claim.accountNumberError = claim.accountNumber === '' || claim.accountNumber == undefined;
                claim.freightCarrierError = claim.freightCarrier === '' || claim.freightCarrier == undefined;
                validData = validData && (!claim.freightCarrierError && !claim.accountNumberError);
            }
        });

        
        setClaims([...newClaims]);
        return validData;
    }

    return (
        <Modal size="lg" show={true} onHide={() => props.setShowModal(false)} backdrop="static" keyboard={false} centered animation={true} >
            <div className="button-wrapper">
                <Modal.Header closeButton>
                    <Modal.Title style={{ paddingLeft: '1rem' }}>{localized.NonFastTrackModalHeader}</Modal.Title>
                </Modal.Header>
            </div>
            <Modal.Body>
                <div className="fele-claim-history">
                    <div className="history-header">
                        <div className="nft-modal-column" style={{ width: '19.5%' }} >
                            <div className="label">{localized.ItemNumber}</div>
                        </div>
                        <div className="nft-modal-column" style={{ width: '19.5%' }} >
                            <div className="label">{localized.ReturnConfirmation}</div>
                        </div >
                        <div className="nft-modal-column" style={{ width: '29.5%' }} >
                            <div className="label">{localized.UpdateFreightCarrier}*</div>
                        </div>
                        <div className="nft-modal-column" style={{ width: '29.5%' }} >
                            <div className="label">{localized.UpdateCarrierAccountNumber}*</div>
                        </div>
                    </div>
                    <div>
                        <div className="history" style={{ overflowY: 'scroll', height: '28rem' }}>
                            {
                                claims.map((item, key) => renderClaim(item, key))
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="button-wrapper">
                    <Button variant="secondary" className="button button-secondary" onClick={submit}>{localized.NonFastTrackModalSubmit}</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
NonFastTrackModal.propTypes = {
    claims: PropTypes.array,
    addNonFastTrackFields: PropTypes.func,
    setShowModal: PropTypes.func
}
export default NonFastTrackModal;