import React from 'react';
import PropTypes from 'prop-types';
import AngleDown from '../icons/AngleDown';
import DoubleDownArrow from '../icons/DoubleDownArrow';
import AnimateHeight from 'react-animate-height';
import Select from 'react-select';
import { withWarrantyConfig } from '../../hooks/WarrantyConfigContext';

export class ClaimsFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            animateHeight: 0,
            claimStatusFilters: []
        }
    }


    toggleAnimation = () => {
        this.setState({
            animateHeight: this.state.animateHeight === 0 ? "auto" : 0,
        })
    }

    handleEnter = (e) => {
        if (e.key === "Enter")
            this.props.fetchClaims();
    }

    render() {
        const customSelectStyles = {
            option: (provided) => ({
                ...provided,
                fontSize: '1.5rem',

            }),
            control: base => ({
                ...base,
                height: 35,
                minHeight: 35,
                fontSize: "1.4rem"
            })
        };

        const { fetchClaims, searchValue, setStatus, dateRange, setSearchValue,
            setDateRange, claims, canApproveRejectClaim, resetChanges, saveChangesClaimApproval, claimStatusFilters } = this.props;
        const localized = this.props.WarrantyConfig.labels;

        return (
            <div className="fele-order-history-filter">
                <div className="button-wrapper filter-trigger">
                    <button data-cy="filter-trigger" className="button" onClick={this.toggleAnimation}>
                        {localized.SearchForClaims}
                        <DoubleDownArrow />
                    </button>
                </div>

                <AnimateHeight duration={500} height={this.state.animateHeight}>
                    <div className="flex">
                        <div data-cy="claim-status" style={{ fontSize: '1.4rem' }} className="claim-status">
                            <div className="label">{localized.ClaimStatus}</div>
                            <div className="fele-content">
                                <div className="select-wrapper">
                                    <Select styles={customSelectStyles} options={claimStatusFilters}
                                        onChange={setStatus} menuPortalTarget={document.querySelector('body')} isClearable={true} />
                                </div>
                            </div>
                        </div>

                        <div className="claim-filter-date-range">
                            <div className="label">{localized.DateRange}</div>
                            <div className="fele-content">
                                <div className="select-wrapper">
                                    <select onChange={setDateRange} value={dateRange}>
                                        <option value="30">{localized.Last30Days}</option>
                                        <option value="90">{localized.Last90Days}</option>
                                        <option value="365">{localized.LastYear}</option>
                                        <option value="730">{localized.Last2Years}</option>
                                    </select>
                                    <AngleDown />
                                </div>
                            </div>
                        </div>

                        <div className="br-claim-search">
                            <div className="label">{localized.ClaimFilterSearch}</div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <input data-cy="referencenumber-search" type="text" placeholder={localized.Search} onChange={setSearchValue} onKeyPress={this.handleEnter} value={searchValue} />
                                </div>

                            </div>
                        </div>
                        <div className="claim-submission-button">
                            <div className="label">&nbsp;</div>
                            <div className="fele-content">
                                <div className="button-wrapper small">
                                    <button data-cy="claim-submit-search" className="button search-button button-alt" onClick={fetchClaims}>{localized.Search}</button>
                                </div>
                            </div>
                        </div>
                        <div className="claim-reset-button">
                            <div className="label">&nbsp;</div>
                            <div className="fele-content">
                                {
                                    canApproveRejectClaim && (claims && claims.length > 0) &&
                                    <div className="button-wrapper small">
                                        <button data-cy="claim-reset-changes"
                                            className="button button-secondary search-button button-alt" onClick={resetChanges}
                                            disabled={claims?.filter(o => o.approvalStatus)?.length === 0}>
                                            {localized.ResetButtonText}&nbsp;</button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="claim-save-changes-button">
                            <div className="label">&nbsp;</div>
                            <div className="fele-content">
                                {
                                    canApproveRejectClaim && (claims && claims.length > 0) &&
                                    <div className="button-wrapper">
                                        <button data-cy="claim-save-changes" className="button search-button button-alt"
                                            onClick={saveChangesClaimApproval}>{localized.SaveChangesButtonText}</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </AnimateHeight>
            </div>
        );
    }
}

ClaimsFilter.propTypes = {
    fetchClaims: PropTypes.func,
    setItemNumber: PropTypes.func,
    setReferenceNumber: PropTypes.func,
    setSerialNumber: PropTypes.func,
    setDateRange: PropTypes.func,
    setStatus: PropTypes.func,
    dateRange: PropTypes.string,
    statusFilter: PropTypes.string,
    itemNumberFilter: PropTypes.string,
    referenceNumberFilter: PropTypes.string,
    serialNumberFilter: PropTypes.string,
    resetChanges: PropTypes.func,
    saveChangesClaimApproval: PropTypes.func,
    claimNumberFilter: PropTypes.string
}

export default withWarrantyConfig(ClaimsFilter);