import React, { useState } from 'react';
import DateDisplay from '../DateDisplay';
import CheckBox from '../form-controls/CheckBox';
import AngleDown from '../icons/AngleDown';
import OrderAckIcon from '../icons/OrderAckIcon';
import QuoteIcon from '../icons/QuoteIcon';
import { useWarrantyConfig } from '../../hooks/WarrantyConfigContext';
import Api from '../../services/Api';
import Notifications from "../../services/Notifications";
import SubmitModal from '../shared/SubmitModal';
import { Container, Row, Col } from 'react-bootstrap';
import StepProgressBar from '../shared/StepProgressBar';
import * as Roles from '../../resources/UserRole';
import CommentDetailsModal from '../claims/CommentDetailsModal';


const ClaimRecord = (props) => {
    const { labels: localized } = useWarrantyConfig();
    const [collapsed, setCollapsed] = useState(false);
    const [steps, setSteps] = useState([]);
    const [isDescriptionStep, setIsDescriptionStep] = useState(false);
    const [itemNumberSubmit, setItemNumberSubmit] = useState('');
    const [serialNumberSubmit, setSerialNumberSubmit] = useState('');
    const [detailCommentSumit, setDetailCommentSumit] = useState('');




    const [showDetailModal, setShowDetailModal] = useState(false);

    const clickableStyle = {
        cursor: 'pointer'
    }

    const handleError = (res) => {
        if (!res)
            Notifications.error(localized.NoRMAMessage);
    }

    const getRMA = (claim) => {
        Api.downloadFileName(`/api/claims/GetRMA/${claim.billToCustomer}/${claim.rmA_PONumber}/${claim.documentType}`, `${claim.billToCustomer}-${claim.rmA_PONumber}${claim.documentType}`).then(handleError);
    }
    const getCommentTextFile = (claim) => {
        Api.downloadFileName(`/api/claims/getCommentTextFile/${claim.billToCustomer}/${claim.serialNumber}/${claim.documentType}`, `${claim.billToCustomer}-${claim.serialNumber}-WarrantyTextFile`).then(handleError);
    }
    

    const validRMAStatusCode = (statusCode) => {
        let showRMA = false;
        // when status is between 129-900 AND 1PM AND 999 then display PDF otherwise do not.
        if (!isNaN(statusCode)) {
            const code = parseInt(statusCode);
            showRMA = code > 129 && code <= 900 || code == 999;
        }
        else if (statusCode === '1PM') {
            showRMA = true; 13
        }
        return showRMA;
    }

    // filtering claims status for step progress bar based on return policy (Fast Track/Non Fast Track) and disposition type
    const openModal = (claim) => {
        let filteredStatusList = props.claimsStatus?.filter(s => s.returnPolicy === claim.returnPolicy && s.shown === true);
        const defaultStatusList = filteredStatusList;
        let filteredStatusByType = defaultStatusList?.filter(s => s.type === claim.type);
        const key = 'descriptionText';
        if (claim.type === "Analysis Only" && claim.providerGroup != null) {
            filteredStatusByType = filteredStatusList.filter(x => x.providerGroup === claim.providerGroup);
            if (filteredStatusList.length === 0) {
                //revert to default for analysis only where provider not found in list
                filteredStatusByType = defaultStatusList;
            };
            if (!filteredStatusList.some(x => x.caseType === claim.caseType) || claim.caseType == null) {
                filteredStatusByType = filteredStatusList.filter(x => x.caseType == null);
            }
        }
        const statusList = filteredStatusByType?.length === 0 && claim.returnPolicy == ' ' ? defaultStatusList : filteredStatusByType;
        const filterStatusList = [...new Map(statusList?.map(item => [item[key], item])).values()];// same description different status code its warranty
        setSteps(filterStatusList);
        //  non fast track status have same desc diff status code
        (filterStatusList?.filter(s => s.descriptionText == claim.statusDescription)?.length === 0) ? setIsDescriptionStep(false) : setIsDescriptionStep(true);

        setCollapsed(!collapsed);
    }


    const openDetailsModal = (claim) => {

        setItemNumberSubmit(claim.itemNumber);
        setSerialNumberSubmit(claim.serialNumber);
        setDetailCommentSumit(claim.detailsComments);
        setShowDetailModal(true);
    }

    const hideConfirmation = () => {
        setShowDetailModal(false);
    }

    const detailCommentUpdated = (detailComment) => {

    }

    const { claim, statusCodes, setClaimApproval } = props;
    return (
        <div className="detail-row">
            <div className="details">
                <div className="order-number" onClick={() => openModal(claim)} style={clickableStyle}>
                    <div className="label">{localized.ClaimNumber}</div>
                    <div className="fele-content">
                        {claim.caseNumber}-{claim.lineNumber}
                    </div>
                </div>
                <div className="order-number" onClick={() => openModal(claim)} style={clickableStyle}>
                    <div className="label">{localized.ItemNumber}</div>
                    <div className="fele-content">{claim.itemNumber}</div>
                </div>
                <div className="item-description" onClick={() => openModal(claim)} style={clickableStyle}>
                    <div className="label">{localized.SerialNumber}</div>
                    <div className="fele-content">{claim.serialNumber}</div>
                </div>
                <div className="date" onClick={() => openModal(claim)} style={clickableStyle} >
                    <div className="label" >{localized.ClaimDate}</div>
                    <div className="fele-content" >
                        <DateDisplay date={claim.dateEntered} />
                    </div>
                </div>
                <div className="status" onClick={() => openModal(claim)} style={clickableStyle}>
                    <div className="label">{localized.Status}</div>
                    <div className="fele-content">{claim.statusDescription}</div>
                </div>
                <div className="order-number" onClick={() => openModal(claim)} style={clickableStyle}>
                    <div className="label">{localized.ReferenceNumber}</div>
                    <div className="fele-content">{claim.referenceNumber}</div>
                </div>
                <div className="fast-track" onClick={() => openModal(claim)} style={clickableStyle}>
                    <div className="label">{localized.FastTrack}</div>
                    <div className="fele-content">
                        {
                            claim.returnPolicy && claim.returnPolicy.trim() == "F" &&
                            <CheckBox checked={true} />
                        }
                    </div>
                </div>
                {props.roles.includes(Roles.CAN_APPROVE_REJECT_CLAIM) &&
                    <>
                        <div className="claim-action">
                            <div className="label"></div>
                            <div className="fele-content">
                                {
                                    claim.returnPolicy != "F" && (statusCodes?.find(o => o.code === claim.statusCode)?.enabled === true) &&
                                    <div className="select-wrapper">
                                        <select value={claim.approvalStatus} onChange={(e) => { setClaimApproval(claim.caseNumber, e) }}>
                                            <option value=''>{localized.Pending}</option>
                                            <option value='1'>{localized.Approve}</option>
                                            <option value='0'>{localized.Reject}</option>
                                        </select>
                                        <AngleDown />
                                    </div>
                                }
                                {
                                    claim.returnPolicy != "F" && statusCodes?.find(o => o.code === claim.statusCode)?.enabled === false &&
                                    <div className="fele-content">
                                        {statusCodes.find(o => o.code === claim.statusCode)?.status}
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
                <div className="rma">
                    {claim.returnPolicy != "F" && validRMAStatusCode(claim.statusCode) ?
                        <div style={{ cursor: 'pointer' }} onClick={() => { getRMA(claim); }}>
                            <OrderAckIcon />
                        </div>
                        : ''
                    }

                </div>
                <div className="order-number">
                    <div className="label">{localized.Comments}</div>
                    <div className="fele-content">{claim.comments}</div>
                    {
                        claim.detailsComments != "" && claim.detailsComments != null &&
                        <div className="button-wrapper" style={{ minWidth: '7rem', height: '1.7rem' }} >
                            <button onClick={() => openDetailsModal(claim)} style={clickableStyle}>
                                    <a className="link" style={{ fontWeight: "bold", fontSize: '1.2rem' }} >{localized.DetailedComments}</a>
                            </button>

                            {/*<div style={{ cursor: 'pointer' }} onClick={() => { getCommentTextFile(claim); }}>*/}
                            {/*    <QuoteIcon />*/}
                            {/*</div>*/}
                        </div>
                    }
                </div>
                {/*<div className="order-number" >*/}
                {/*    {*/}
                {/*        claim.detailsComments != "" && claim.detailsComments != null &&*/}
                {/*        <div className="button-wrapper" style={{ minWidth: '7rem', height: '1.7rem' }} >*/}
                {/*            <button onClick={() => openDetailsModal(claim)} style={clickableStyle}>*/}
                {/*                <a className="link" style={{ fontWeight: "bold", fontSize: '1.2rem' }} > {"Detailed Comment"}</a>*/}
                {/*            </button>*/}
                            
                {/*                */}{/*<div style={{ cursor: 'pointer' }} onClick={() => { getCommentTextFile(claim); }}>*/}
                {/*                */}{/*    <QuoteIcon />*/}
                {/*                */}{/*</div>*/}
                {/*        </div>*/}
                {/*    }*/}
                   
                {/*</div>*/}
            </div>

            <CommentDetailsModal
                show={showDetailModal}
                onHide={hideConfirmation}
                callback={hideConfirmation}
                localized={localized}
                itemNumber={itemNumberSubmit}
                serialNumber={serialNumberSubmit}
                detailComment={detailCommentSumit}
                detailCommentUpdated={detailCommentUpdated}
                showFooter={false}
                IsDetailOnly={true}
            />

            <SubmitModal
                isOpen={collapsed}
                onClose={() => { setCollapsed(!collapsed); }}
                showFooter={false}
                size={"lg"}
            >
                <Container className="warranty-status-modal">
                    <Row className="heading1">
                        <Col xl={6}>
                            <div className="text-left">
                                <div >{localized.ClaimNumber} {claim.caseNumber}-{claim.lineNumber}</div>
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className="text-right">
                                {
                                    claim.returnPolicy && claim.returnPolicy.trim() == "F" &&
                                    <div className="d-flex justify-content-end align-items-center"><CheckBox checked={true} /> {localized.FastTrack}</div>
                                }
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xl={3} style={{ textAlign: 'right' }}>
                            <div className="heading4">{localized.Comments}:</div>
                        </Col>
                        <Col xl={9}>
                            <div>{claim.comments}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={{ textAlign: 'right' }}>
                            <div className="heading4">{localized.EnteredBy}:</div>
                        </Col>
                        <Col xl={9}>
                            <div><a mailto={claim.enteredByEmailAddress}>{claim.enteredByName}</a></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={{ textAlign: 'right' }}>
                            <div className="heading4">{localized.DateInstalled}:</div>
                        </Col>
                        <Col xl={9}>
                            <div><DateDisplay date={claim.installDate} /></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={3} style={{ textAlign: 'right' }}>
                            <div className="heading4">{localized.DateFailed}:</div>
                        </Col>
                        <Col xl={2}>
                            <div><DateDisplay date={claim.failureDate} /></div>
                        </Col>
                        <Col xl={2} style={{ textAlign: 'right' }}>
                            <div className="heading4">{localized.SerialNumber}:</div>
                        </Col>
                        <Col xl={2}>
                            <div>{claim.serialNumber}</div>
                        </Col>
                        <Col xl={2} style={{ textAlign: 'right' }}>
                            <div className="heading4">{localized.LoyaltyID}:</div>
                        </Col>
                        <Col xl={1}>
                            <div>{claim.loyaltyID}

                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {
                                steps?.length > 0 &&
                                <div style={{ margin: 70 }}>
                                    <StepProgressBar
                                        width={600}
                                        steps={steps}
                                        isDescriptionStep={isDescriptionStep} //optional to use this property for progress bar in other places, this is needed for NFT status
                                        stepDescription={claim.statusDescription}
                                        stepCode={claim.statusCode}
                                    >
                                    </StepProgressBar>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </SubmitModal>
        </div >
    );
}

export default ClaimRecord;