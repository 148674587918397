import React from 'react';
import PropTypes from 'prop-types';
import AngleDown from '../icons/AngleDown';
import Api from '../../services/Api';
import { withCartConfig } from '../../hooks/CartConfigContext';

export class StorefrontCarriers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storefrontCarriers: [],
            storefrontCarrierId: props.storefrontCarrierId || "",
            storefrontCarrier2Id: props.storefrontCarrier2Id || ""
        };
    }

    componentDidMount() {
        this.mounted = true;
        this.loadStorefrontCarriers();
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    setMountedState = (state, callback) => {
        if (this.mounted)
            this.setState(state, () => callback && callback());
    }

    loadStorefrontCarriers = () => {
        if (this.carriersEnabled()) {
            Api.fetch(`/api/customer/${this.props.selectedCustomer.id}/carriers`).then((carriers) => {
                if (carriers) {
                    this.setMountedState({ storefrontCarriers: carriers });
                }
            });
        }
    }

    updateCarriers = () => {
        const carriers = { carrier1: this.state.storefrontCarrierId, carrier2: this.state.storefrontCarrier2Id };
        this.props.updateCarriers(carriers);
    }

    handleChange = (e) => {
        this.setMountedState({ storefrontCarrierId: e.target.value }, this.updateCarriers);
    }

    handleCarrier2Change = (e) => {
        this.setMountedState({ storefrontCarrier2Id: e.target.value }, this.updateCarriers);
    }

    carriersEnabled = () => {
        return this.props.CartConfig.childComponents.Carrier1.shown === true || this.props.CartConfig.childComponents.Carrier2.shown === true;
    }

    render() {
        if (!this.carriersEnabled()) {
            return null;
        }
        const { localized } = this.props;
        return (
            <div className="section">
                <div className="heading3">
                    {localized.StorefrontCarriersLabel}
                </div>
                <div className="fele-cart-storefront-carriers">
                    <div className="fele-content">
                        <div className="input-wrapper">
                            {
                                this.props.CartConfig.childComponents.Carrier1.shown === true &&
                                <div className="select-wrapper">
                                    <select onChange={this.handleChange} value={this.state.storefrontCarrierId}>
                                        <option value="" style={{ color: 'gray' }}>{localized.SelectACarrier}</option>
                                        {
                                            this.state.storefrontCarriers.map(
                                                (storefrontCarrier, index) =>
                                                    <option key={index} value={storefrontCarrier.id}>{storefrontCarrier.name}</option>
                                            )}</select>
                                    <AngleDown />
                                </div>
                            }
                            {
                                this.props.CartConfig.childComponents.Carrier2.shown === true &&
                                <div className="select-wrapper">
                                    <select onChange={this.handleCarrier2Change} value={this.state.storefrontCarrier2Id}>
                                        <option value="" style={{ color: 'gray' }}>{localized.SelectACarrier2}</option>
                                        {
                                            this.state.storefrontCarriers.map(
                                                (storefrontCarrier, index) =>
                                                    <option key={index} value={storefrontCarrier.id}>{storefrontCarrier.name}</option>
                                            )}</select>
                                    <AngleDown />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


StorefrontCarriers.propTypes = {
    carriersUpdated: PropTypes.func,
    selectedCustomer: PropTypes.object,
    storefrontCarrierId: PropTypes.string,
    storefrontCarrier2Id: PropTypes.string,
};

export default withCartConfig(StorefrontCarriers);