import Api from './Api';
import store from '../store/configureStore';

class Customer {
    callbacks = [];
    customers = [];
    search = "";

    set(customer) {
        this.storeSelectedCustomer(customer);
    }

    subscribe(callback, tag = '') {
        this.callbacks.push({ key: tag, value: callback });
    }

    updateSubscribers(tag = '') {
        this.callbacks.forEach(cb => {
            if (!tag || !cb.key || cb.key === tag) {
                cb.value();
            }
        });
    }

    fetchCustomers = () => {
        return Api.fetch(`/api/user/customerList`);
    }

    storeSelectedCustomer(customer) {
        localStorage.setItem("fele-ecommerce_selectedCustomer", JSON.stringify(customer));
    }

    lookupCustomerPreferences(customer) {
        // do the things. We'll pass in territoryCode later for Sales Reps
        var jsonBody = JSON.stringify({ externalId: customer.externalId, territoryCode: "", companyCode: customer.companyCode });

        return Api.fetch(`/api/customer/customerpreferences`, {
            method: 'POST', body: jsonBody
        }).then((res) => {
            if (res)
                return res.json();
        });
    }

    getTransitDays(storefrontNumber, customerNumber) {
        return Api.fetch('/api/customer/transitdays/' + storefrontNumber + '/' + customerNumber, { method: 'GET' })
            .then((res) => {
                return res;
            });
    }

    getPaymentTerms(storefrontNumber, brandCode) {
        return Api.fetch('/api/customer/paymentTerms/' + storefrontNumber + '/' + brandCode , {method: 'GET'}).then((result) => {
            if (result) {
                return result;
            } else {
                return [];
            }
        });
    }

    handleSubmitOrderResponse(res) {
        if (res)
            return res.json();
    }
    storedSelectedCustomer() {
        let selectedCustomer = localStorage.getItem("fele-ecommerce_selectedCustomer");
        if (selectedCustomer !== "undefined")
            selectedCustomer = selectedCustomer && JSON.parse(selectedCustomer)
        if (this.isValidCustomer(selectedCustomer))
            return selectedCustomer;
        return null;
    }

    isValidCustomer(customer) {
        return customer && (customer !== "undefined") && this.customerExistsInCustomers(customer);
    }

    getSelectedCustomer() {
        return store.getState().user.selectedCustomer;
    }

    customerExistsInCustomers(customer) {

        const custList = store.getState().user.customers;
        if (custList.filter(c => c.externalId === customer.externalId).length > 0)
            return true;
        for (var i = 0; i < custList.length - 1; i++) {
            if (custList[i].children && custList[i].children.filter(c => c.externalId === customer.externalId).length > 0)
                return true;
        }
        return false;
    }

    updateSearch = (value) => {
        this.search = value;
        this.fetchCustomers();
    }

    handleError = () => {
        console.log("Failed to load customers.")
    }

    checkCreditHold = (customer) => {
        /* Comment until Latin America business requires credit hold warning
        if (customer.creditHold > 0) {
            return true;
        }*/
        return false;
    }

    get12MonthSales = (shipTo, parent) => {
        // [HttpGet("sales/{shipToId}/{parentId}")]
        return Api.fetch(`/api/account/sales/${shipTo}/${parent}`);
    }

    getSalesByCategory = (fromDate, toDate, territoryCode, comapnyCode, customerId, callback) => {
        let data = {
            FromDate: fromDate,
            ToDate: toDate,
            TerritoryCode: territoryCode || null,
            CompanyCode: comapnyCode || null,
            CustomerId: customerId || null
        };
        return Api.fetch(`/api/account/salesByCategory`, { method: 'POST', body: JSON.stringify(data) })
        .then((res) => {
            if (res)
                return res.json()
            })
        .then(callback);
    }

    getCustomersByTerritory = (territoryCode, companyCode) => {
        return Api.fetch(`/api/account/customersByTerritory/${territoryCode}/${companyCode}`);
    }
}

export default new Customer();
