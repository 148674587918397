import * as React from 'react';
import { useState, useEffect } from 'react';
import storefrontService from '../services/Storefront';
import { useStorefrontSettings } from './StorefrontSettingsContext';
import Localization from '../services/Localization';
import * as Components from '../resources/Components';

const UserConfigContext = React.createContext();

function UserConfigProvider({ children, ...props }) {

    // have all the config settings here.
    const [labels, setLabels] = useState({});
    const { locale } = useStorefrontSettings();

    // might have to just make this a parameter that gets passed in
    const storefrontNumber = ('00000' + props.storefrontNumber).slice(-5);

    useEffect(() => {
        Localization.getComponentLabels(Components.USERS, locale, storefrontNumber)
            .then(res => {
                setLabels(res);
            })
    }, [locale]);

    return (
        <UserConfigContext.Provider
            value={{
                labels
            }}
        >
            {children}
        </UserConfigContext.Provider >
    );
}

function useUserConfig() {
    const context = React.useContext(UserConfigContext);
    if (context === undefined) {
        throw new Error('UserConfigContext must be used within a UserConfigContext');
    }
    return context;
}

function withUserConfig(Component) {
    return function WrappedComponent(props) {
        const UserConfig = useUserConfig();
        return <Component {...props}
            UserConfig={UserConfig}
        />;
    }
}

export { UserConfigProvider, useUserConfig, withUserConfig }