import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as Roles from '../resources/UserRole';
import ClaimService from '../services/ClaimService';
import Notifications from "../services/Notifications";
import Claims from './claims/Claims';
import NonFastTrackModal from './claims/NonFastTrackModal';
import ClaimsFilter from './claims/ClaimsFilter';
import ClaimsSubmission from './claims/ClaimsSubmission';
import Paging from './Paging';
import DateHelper from '../helpers/DateHelper';
import UserHelper from '../helpers/UserHelper';
import { withSelectedCustomer } from '../hooks/withSelectedCustomer';
import { withStorefrontConfig } from '../hooks/StorefrontSettingsContext';
import { withWarrantyConfig } from '../hooks/WarrantyConfigContext';

export class ClaimHistory extends React.Component {
    constructor(props) {
        super(props);
    
        this.localized = this.props.WarrantyConfig.labels;

        this.state = {
            dateRange: "30",
            statusDescription: '',
            claimType: '',
            approvalStatus: '',
            page: 1,
            pageSize: 100,
            claims: null,
            count: null,
            fetching: false,
            customerId: props && props.selectedCustomer ? props.selectedCustomer.id : null,
            billToCustomer: props && props.selectedCustomer ? props.selectedCustomer.billToId : null,
            storefrontId: props && props.currentUser.storefrontId,
            commentsSubmit: '',
            detailsCommentSubmit:'',
            itemNumberSubmit: '',
            serialNumberSubmit: '',
            dateInstalledSubmit: '',
            dateFailedSubmit: '',
            referenceNumberSubmit: '',
            loyaltyIDSubmit: '',
            itemNumberError: false,
            dateInstalledError: false,
            installDateError: false,
            commentsError: false,
            failureDateError: false,
            fastTrackError: false,
            claimLines: [],
            claimApproval: [],
            canApproveRejectClaim: props.currentUser.roles.includes(Roles.CAN_APPROVE_REJECT_CLAIM) ? true : false,
            serialNumberWarning: false,
            loyalityIDWarning: false,
            showNonFastTrackModal: false,
            referenceNumberError: false,
            claimTypeOptionError: false,
            allowReplacement: false,
            returnPolicy: '',
            obsolete: false,
            duplicateSerial: false,
            serialNumberClassName: '',
            serialNumberErrors: false,
            isValidClaim: false,
            searchValue: '',
            pioneerBrand: {},
            isPioneerItem: false,
            uploadFiles: [],
            itemDispositions: [],
            allDispositions: []
        };
    }

    componentDidMount() {
        this.updateClaims();
        this.fetchStatusCodes();
        this.fetchClaimsStatus();
        this.fetchClaimDispositions();
    }

    fetchClaimDispositions() {
        if (this.props.selectedCustomer) {
            const storefrontId = this.state.storefrontId || this.props.currentUser.storefrontId;
            return ClaimService.fetchDispositions(storefrontId).then(this.loadDispositions);
        }
    }

    loadDispositions = (response) => {
        if (response)
            this.setState({
                allDispositions: response
            });
    }

    componentDidUpdate() {
        if (this.props && this.props.selectedCustomer && this.state.customerId !== this.props.selectedCustomer.id) {
            this.setState({
                customerId: this.props.selectedCustomer.id,
                billToCustomer: this.props.selectedCustomer.billToId
            }, this.updateClaims);
            this.resetChanges();
            this.fetchStatusCodes();
            this.fetchClaimDispositions();
        }
    }

    setStatus = (e) => {
        let status = null;
        status = e?.value;
        this.setState({ status: status, page: 1 }, this.updateClaims);
    }

    setSearchValue = (e) => {
        this.setState({ searchValue: e.target.value, page: 1 });
    }

    setDateRange = (e) => {
        this.setState({ dateRange: e.target.value, page: 1 }, this.updateClaims);
    }

    setClaimType = (e) => {
        this.setState({ claimTypeOptionError: false });
        const selectedOption = {
            value: e.target.value,
            name: e.target.options[e.target.selectedIndex].text
        }
        this.setState({ claimType: selectedOption });
    }
    setClaimCredit = (setCredit) => {
        if (setCredit) {
            this.setState({ claimType: { value: '1', name: 'Credit' } });
        }
        else {
            this.setState({ claimType: '' });
        }
    }
    setAnalysisOnlyClaimType = () => {
        this.setState({ claimType: { value: '3', name: 'Analysis Only' } });
    }

    setPioneerItem = (e) => {
        this.setState({ isPioneerItem: e });
    }

    setItemNumberSubmit = (e) => {
        this.setState({ obsolete: false });
        this.setState({ itemNumberSubmit: e.target.value });
    }

    setSerialNumberSubmit = (e) => {
        this.setState({ duplicateSerial: false });
        if (UserHelper.checkSpecialCharacters(e.target.value)) {
            this.setState({ serialNumberErrors: true });
            this.setState({ serialNumberClassName: "warning-textbox" });
        }
        else {
            this.setState({ serialNumberSubmit: e.target.value });
            this.setState({ serialNumberErrors: false });
            this.setState({ serialNumberClassName: "" });
        }
    }

    setDuplicateSerial = (isDuplicate) => {
        this.setState({ duplicateSerial: isDuplicate });
    }

    setSerialNumberWarning = (isWarning) => {
        this.setState({ serialNumberWarning: isWarning });
    }

    checkAllAreDigits = (value, index) => {
        if (value.length === 0) return true;
        if (!index) index = 0;
        if (isNaN(value[index])) return false;
        if (index === value.length - 1) return true;
        return this.checkAllAreDigits(value, ++index);
    }

    setLoyaltyIDSubmit = (e) => {
        if (this.checkAllAreDigits(e.target.value)) {
            this.setState({ loyalityIDWarning: false });
            this.setState({ loyaltyIDSubmit: e.target.value });
        }
        else {
            this.setState({ loyalityIDWarning: true });
        }
    }

    setDateInstalledSubmit = (date) => {
        let dateFormat = this.props.StorefrontConfig.settingsList.DateFormat ? this.props.StorefrontConfig.settingsList.DateFormat : "MM/dd/yyyy";
        this.setState({ dateInstalledSubmit: DateHelper.format(date, dateFormat) });
    }

    setDateFailedSubmit = (date) => {
        let dateFormat = this.props.StorefrontConfig.settingsList.DateFormat ? this.props.StorefrontConfig.settingsList.DateFormat : "MM/dd/yyyy";
        this.setState({ dateFailedSubmit: DateHelper.format(date, dateFormat) });
    }

    setReferenceNumberSubmit = (e) => {
        this.setState({ referenceNumberSubmit: e.target.value });
    }

    setCommentsSubmit = (e) => {
        this.setState({ commentsSubmit: e.target.value });
    }

    setDetailsCommentSubmit = (e) => {
        this.setState({ detailsCommentSubmit: e });
    }

    pageCount = () => {
        return Math.ceil(this.state.count / this.state.pageSize);
    }

    nextPage = () => {
        if (this.state.page < this.pageCount()) {
            this.setState({ page: this.state.page + 1 }, this.fetchClaims);
        }
    }

    previousPage = () => {
        if (this.state.page > 1) {
            this.setState({ page: this.state.page - 1 }, this.fetchClaims);
        }
    }

    updateClaims = () => {
        this.fetchClaims();
    }

    fetchClaims = () => {
        if (this.props.selectedCustomer) {
            this.setState({ fetching: true });
            return ClaimService.fetchClaims(this.state, this.props).then(this.loaded);
        }
    }

    fetchStatusCodes = () => {
        if (this.props.selectedCustomer) {
            return ClaimService.fetchStatusCodes().then(this.loadStatusCodes);
        }
    }

    loadStatusCodes = (response) => {
        if (response)
            this.setState({
                statusCodes: response
            });
    }

    // fetch warranty all status information
    fetchClaimsStatus = () => {
        if (this.props.selectedCustomer) {
            const storefrontId = this.state.storefrontId || this.props.currentUser.storefrontId;
            return ClaimService.fetchClaimsStatus(storefrontId).then(this.loadClaimsStatus);
        }
    }

    loadClaimsStatus = (response) => {
        if (response)
            this.setState({
                claimsStatus: response.warrantyClaimStatus
            });
    }

    loaded = (data) => {
        if (data)
            this.setState({
                fetching: false,
                claims: data.warrantyClaims,
                count: data.totalCount,
            });

        var statusResult = [];
        const key = 'statusDescription';
        const filterStatus = [...new Map(this.state.claims?.map(item => [item[key], item])).values()];
        filterStatus.forEach((o) => {
            statusResult.push({ value: o.statusDescription, label: o.statusDescription });
        });

        this.setState({
            claimStatusFilters: statusResult
        });
    }

    addClaimItem = async () => {
        return this.validateSubmission().then(async (valid) => {
            if (valid) {
                var lineNumber;
                var fastTrackValid = this.state.returnPolicy;
                if (fastTrackValid === 'F') {
                    let fastTrackClaimLines = this.state.claimLines.filter(item => item.fastTrack === true && item.claimType.value === "1");// Credit
                    var newLineIndex = fastTrackClaimLines.length + 1;
                    lineNumber = newLineIndex.toFixed(2);
                }

                if (this.state.fastTrackError == false) {
                    let newClaimLine = {
                        comments: this.state.commentsSubmit,
                        detailsComments: this.state.detailsCommentSubmit,
                        itemNumber: this.state.itemNumberSubmit,
                        serialNumber: this.state.serialNumberSubmit,
                        dateInstalled: this.state.dateInstalledSubmit,
                        dateFailed: this.state.dateFailedSubmit,
                        referenceNumber: this.state.referenceNumberSubmit,
                        loyaltyID: this.state.loyaltyIDSubmit,
                        rmaLineNumber: lineNumber,
                        fastTrack: (fastTrackValid === 'F'),
                        claimType: this.state.claimType,
                        fullName: this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName,
                        emailAddress: this.props.currentUser.email,
                        customerNumber: this.state.customerId,
                        billToCustomer: this.state.billToCustomer,
                        branchPlant: this.props.currentUser.storefrontNumber,
                        returnConfirmation: true,
                        obsolete: this.state.obsolete,
                        pioneerBrand: this.state.pioneerBrand,
                        isPioneerItem: this.state.isPioneerItem,
                        uploadFiles: this.state.uploadFiles,
                        returnPolicy: this.state.returnPolicy
                    };

                    this.setState((prevState) => ({
                        claimLines: [...prevState.claimLines, newClaimLine],
                        commentsSubmit: '',
                        detailsCommentSubmit:'',
                        itemNumberSubmit: '',
                        serialNumberSubmit: '',
                        dateFailedSubmit: '',
                        dateInstalledSubmit: '',
                        referenceNumberSubmit: '',
                        loyaltyIDSubmit: '',
                        fastTrackError: false,
                        claimType: '',
                        fullName: '',
                        emailAddress: '',
                        customerNumber: '',
                        branchPlant: '',
                        obsolete: false,
                        pioneerBrand: '',
                        isPioneerItem: false,
                        returnPolicy: ''
                    }));
                }
            }
            return valid;
        }).then(o => {
            return o;
        });

    }

    validateFastTrack = (itemNumber) => {
        return ClaimService.fetchFastTrackStatus(itemNumber)
            .then(data => {
                this.setState({ returnPolicy: data.returnPolicy });
                //this.setState({ allowCreditReplace: (data.returnPolicy == 'F' || data.returnPolicy == 'G') ? true : false });
                this.setState({ obsolete: data.obsolete });
                this.setState({ pioneerBrand: data.pioneerBrand });
                this.setState({ isPioneerItem: data.pioneerBrand && data.pioneerBrand.shown == true ? true : false });
                //set dispositions
                this.setDispositions(data);
            });
    }
    bypassFastTrackValidation = (returnPolicyBypass,itemNumber) => {
        return ClaimService.fetchFastTrackStatus(itemNumber)
            .then(data => {
                this.setState({ returnPolicy: returnPolicyBypass });
                //this.setState({ allowCreditReplace: (data.returnPolicy == 'F' || data.returnPolicy == 'G') ? true : false });
                this.setState({ obsolete: data.obsolete });
                this.setState({ pioneerBrand: data.pioneerBrand });
                this.setState({ isPioneerItem: data.pioneerBrand && data.pioneerBrand.shown == true ? true : false });
                //set dispositions
                data.returnPolicy= returnPolicyBypass;
                this.setDispositions(data);
            });
    }

    setDispositions = (data) => {
        const filterDispositions = this.state.allDispositions.warrantyClaimDisposition.filter(dt => dt.returnPolicy == data.returnPolicy);
        if (data.returnPolicy == ' ' && this.state.canApproveRejectClaim === false) {
            this.setState({ itemDispositions: filterDispositions.filter(al => al.id != 4) }) // Analysis only based on role
        }
        else {
            this.setState({ itemDispositions: filterDispositions })
        }
    }

    //validateFastTrackSerialNumber = async (serialNumberUnformatted) => {
    //    let serialNumber = serialNumberUnformatted.replace(/[^0-9a-zA-Z]/g, '').replace(/\s/g, '');
    //
    //    let monthLetters = { 'A': 1, 'B': 2, 'C': 3, 'D': 4, 'E': 5, 'F': 6, 'G': 7, 'H': 8, 'J': 9, 'K': 10, 'L': 11, 'M': 12 };
    //    let flag = false;
    //
    //    if (serialNumber.length > 13) {
    //        Notifications.error(this.props.WarrantyConfig.labels.SerialFastTrackLengthError);
    //        flag = true;
    //    }
    //    else {
    //        if (!/^\d{2}([ABCDEFGHJKLMabcdefghjklm]){1}([A-Za-z0-9]){2}[0-3]\d.{5}[A-Za-z0-9]/.test(serialNumber)) {
    //            Notifications.error(this.props.WarrantyConfig.labels.SerialFastTrackFormatError);
    //            flag = true;
    //        }
    //        else {
    //            let serialMonth = serialNumber.charAt(2);
    //            let serialYear = serialNumber.substring(0, 2);
    //            let serialMonthValue = monthLetters[serialMonth.toUpperCase()];
    //            let serialDay = serialNumber.substring(5, 7);
    //            if (serialDay > 31) {
    //                Notifications.error(this.props.WarrantyConfig.labels.SerialFastTrackFormatError);
    //                flag = true;
    //            }
    //            else {
    //                let serialDate = new Date('20' + serialYear, serialMonthValue, serialDay);
    //                let currentDate = new Date();
    //                if (serialDate > currentDate) {
    //                    Notifications.error(this.props.WarrantyConfig.labels.SerialFutureError);
    //                    flag = true;
    //                }
    //
    //                if (DateHelper.subtract(currentDate, { years: 5 }) > serialDate) {
    //                    Notifications.errorLong(this.props.WarrantyConfig.labels.SerialFastTrackAgeError);
    //                    flag = true;
    //                }
    //            }
    //        }
    //    }
    //    this.setState({ fastTrackError: flag });
    //}

    checkSerialNumber = async () => {
        var serialNumber = this.state.serialNumberSubmit.trim();
        //if an item is fast track, make sure the length is no greater than 13 characters
        //temp disable until business figures out what conditions to apply for fast track / when to apply them
        //if (this.state.returnPolicy === 'F') {
        //    await this.validateFastTrackSerialNumber(serialNumber);
        //}
        // No duplicate serial numbers check for pioneer items. They comes in packing might have same serial numbers for multiple claims.
        if (serialNumber != "" && !this.state.isPioneerItem) {
            return await ClaimService.fetchWarrantySerialNumber(serialNumber)
                .then(data => {
                    if (data !== null) {
                        this.setState({ serialNumberClassName: "unavailable" });
                        this.setState({ serialNumberWarning: true });
                        Notifications.error(this.props.WarrantyConfig.labels.DuplicateSerialNumber);
                    }
                    else {
                        this.setState({ serialNumberClassName: "" });
                        this.setState({ serialNumberWarning: false });
                    }
                    return data;
                });
        } else {
            this.setState({ serialNumberErrors: serialNumber === "" });
            this.setState({ serialNumberClassName: "unavailable" });
        }
    }

    validateInstallFailureDate = () => {
        let installDate = this.state.dateInstalledSubmit;
        let failureDate = this.state.dateFailedSubmit;
        var result = 0;
        if (installDate != "") {

            //Warranty cutoff is 60 months
            const currentDate = new Date();
            const cutoffDate = DateHelper.subtract(currentDate, { months: 60 });
            const compareInstallDate = DateHelper.checkDateIsString(installDate, this.props.StorefrontConfig.settingsList.dateFormat);
            const compareFailureDate = DateHelper.checkDateIsString(failureDate, this.props.StorefrontConfig.settingsList.dateFormat);

            // Install Date can't be in the future
            result = DateHelper.compareDates(compareInstallDate, currentDate);
            if (result === 1) Notifications.error(this.localized.DateInTheFuture);
            else {
                // Failure Date can't be in the future
                result = DateHelper.compareDates(compareFailureDate, currentDate);
                if (result === 1) Notifications.error(this.localized.DateInTheFuture);
                else {
                    //if cutoffDate is after installDate, -1 if cutoffDate is before installDate, 0 if equal   
                    result = DateHelper.compareDates(cutoffDate, compareInstallDate);
                    if (result === 1) Notifications.error(this.localized.OutsideReturnPeriodError)
                    else {
                        if (failureDate != "") {
                            // Failure Date should be on or after Install Date
                            result = DateHelper.compareDates(compareInstallDate, compareFailureDate);
                            if (result === 1) Notifications.error(this.localized.FailureDateBeforeInstallDate)
                        }
                    }
                }
            }
        }

        this.setState({ dateInstalledError: result === 1 });
    }

    validateSubmission = async () => {
        // Valid Item Number is required
        var itemNumber = this.state.itemNumberSubmit.trim();
        var validData = true;

        // check for valid serial number
        const validSerialNumber = await this.checkSerialNumber();
        let serialNumber = this.state.serialNumberSubmit.trim();
        if (validSerialNumber != null) {
            validData = false;
        }
        console.log(this.state.claimLines);
        if (this.state.claimLines.filter(o => o.serialNumber === serialNumber).length > 0) {
            this.setState({ serialNumberClassName: "unavailable" });
            this.setState({ serialNumberWarning: true });
            Notifications.error(this.props.WarrantyConfig.labels.DuplicateSerialNumber);
            validData = false;
        }

        return ClaimService.fetchWarrantyItemStatus(itemNumber)
            .then(data => {
                if (data.sku === "" || data.sku === null) {
                    itemNumber = "";
                    validData = false;
                    Notifications.error("Invalid Item Number Entered");
                }
                this.setState({ itemNumberError: itemNumber === "" });


                // reference number is required
                let referenceNumber = this.state.referenceNumberSubmit.trim();
                this.setState({ referenceNumberError: referenceNumber === "" });

                //Comments are required
                let comments = this.state.commentsSubmit.trim();
                this.setState({ commentsError: comments === "" });


                let claimType = this.state.claimType;
                this.setState({ claimTypeOptionError: claimType === "" });

                //Installed Date must be after 60 months ago
                let installDate = this.state.dateInstalledSubmit;
                let failureDate = this.state.dateFailedSubmit;
                this.setState({ installDateError: installDate === "" });
                this.setState({ failureDateError: failureDate === "" });

                this.validateInstallFailureDate();

                var validDate = this.state.dateInstalledError;

                // pioneer warranty validation rules
                if (this.state.isPioneerItem === true) {
                    if (this.state.pioneerBrand && this.state.pioneerBrand.required == true) {
                        return !(itemNumber === "" || comments === "" || referenceNumber === "" || serialNumber === "");
                    }
                    else {
                        return !(itemNumber === "" || comments === "" || referenceNumber === "");
                    }
                }


                var isValid = !(itemNumber === "" || comments === "" || installDate === "" || failureDate === "" || claimType === "" || referenceNumber === "" || serialNumber === "" || validDate === true) && validData;

                return isValid;
            });
    }

    validatePioneerItem = (itemNumber, comments, referenceNumber, serialNumber) => {
        if (this.state.pioneerBrand && this.state.pioneerBrand.shown === true && this.state.pioneerBrand.required == true) {
            var isValidPioneerSnoItem = !(itemNumber === "" || comments === "" || referenceNumber === "" || serialNumber === "");
            return isValidPioneerSnoItem;
        }
        else {
            var isValidPioneerItem = !(itemNumber === "" || comments === "" || referenceNumber === "");
            return isValidPioneerItem;
        }
    }

    removeClaimItem = (e) => {
        let claimLines = this.state.claimLines;
        claimLines.splice(e, 1);
        // Clearing off the duplicate serial numbers validation message on claim submission.
        let validClaimLine = claimLines.filter(o => o.isValid == false);
        if (validClaimLine?.length === 0) {
            this.setState({ isValidClaim: false })
        }
        this.setState({ claimLines: claimLines });
    }

    setShowModal = showModal => {
        this.setState({ showNonFastTrackModal: showModal });
    }

    submitClaim = () => {
        let nonFastTrackClaims = this.state.claimLines.filter(item => item.fastTrack !== true);
        if (nonFastTrackClaims.length > 0) {
            this.setShowModal(true);
        }
        else this.executeSubmit();
    }

    executeSubmit = async (nftClaims) => {
        let fastTrackClaims = this.state.claimLines.filter(item => item.fastTrack === true);
        let nonFastTrackClaims = nftClaims ? nftClaims : this.state.claimLines.filter(item => item.fastTrack !== true);

        this.setState({ fetching: true, showNonFastTrackModal: false });

        // Validate serial numbers on claim submission.
        let validatedClaimLines = await this.validateClaim(fastTrackClaims, nonFastTrackClaims).then(data => {
            if (data) {
                return data;
            }
        });

        // Submit claims only if all the items are valid(no duplicates)
        let valid = validatedClaimLines?.filter(o => o.isValid == false);
        if (valid?.length === 0) {
            this.setState({ isValidClaim: false })
            this.submitClaimToAPI([...fastTrackClaims, ...nonFastTrackClaims]);
        }
        else {
            this.setState({ fetching: false });
            this.setState({ isValidClaim: true })
            this.setState({ claimLines: validatedClaimLines });
            Notifications.errorShow(this.localized.DuplicateClaimSubmission);
        }
    }


    validateClaim = async (fastTrackClaims, nonFastTrackClaims) => {
        return await ClaimService.validateClaim([...fastTrackClaims, ...nonFastTrackClaims]).then(response => {
            if (response) {
                return response;
            }
        });
    }

    claimFailureResponse = () => {
        this.setState({ fetching: false });
        Notifications.errorShow(this.localized.ClaimSubmitError);
    }

    claimSuccessResponse = () => {
        Notifications.message(this.localized.ClaimSubmitted);
        this.fetchClaims();
    }

    uploadClaimAttachments = (files, claimLine) => {
        const claimsAttachmentsToUpload = this.state.claimLines.map(claim => {
            if (claim === claimLine) {
                return {
                    ...claim,
                    uploadFiles: [...files]
                }
            }
            else {
                return {
                    ...claim
                }
            }
        });
        this.setState({ claimLines: claimsAttachmentsToUpload })
    }

    submitClaimWithoutAttachmentsToAPI = (claimsWithoutAttachments) => {
        if (claimsWithoutAttachments.length > 0) {
            ClaimService.submitClaim(claimsWithoutAttachments).then(res => {
                if (res) {
                    this.claimSuccessResponse();
                    this.setState({ claimLines: [] }); //TODO handle failure scenarios 
                } else {
                    this.claimFailureResponse();
                }
            });
        }
    }

    submitClaimWithAttachmentsToAPI = (claimsWithAttachments) => {
        const claimsWithoutAttachments = this.state.claimLines.filter(item => item.uploadFiles?.length == 0);
        if (claimsWithAttachments.length > 0) {
            claimsWithAttachments.map(cl => {
                ClaimService.submitClaimWithAttachment(cl).then(res => {
                    if (res !== undefined) {
                        if (claimsWithoutAttachments.length == 0) { // This check because to avoid confusion to user multiple toasts for combination of claims
                            this.claimSuccessResponse();
                            let removeClaimLine = claimsWithAttachments.filter(o => o.serialNumber != cl.serialNumber);
                            this.setState({ claimLines: removeClaimLine });
                        }
                        // Upload attachments to azure and send to E1.
                        ClaimService.uploadAttachment(cl.uploadFiles, res["Case Number"], cl.customerNumber).then(response => {
                            if (response) {
                                Notifications.message(this.localized.AttachmentSubmitted);
                            }
                            else {
                                Notifications.error(this.localized.AttachmentError);
                            }
                        })
                    } else {
                        if (claimsWithoutAttachments.length == 0) {
                            this.claimFailureResponse();
                        }
                    }
                });
            });
        }
    }

    submitClaimToAPI = (claimLines) => {
        let claimsWithAttachments = claimLines.filter(item => item.uploadFiles?.length > 0);
        let claimsWithoutAttachments = claimLines.filter(item => item.uploadFiles?.length == 0);
        this.submitClaimWithAttachmentsToAPI(claimsWithAttachments);
        this.submitClaimWithoutAttachmentsToAPI(claimsWithoutAttachments);
    }

    handleEnter = (e) => {
        if (e.key === "Enter")
            this.addClaimItem();
    }

    handleBlur = async () => {
        var productReturnPolicy = await this.validateFastTrack();

    }

    setClaimApproval = (caseNumber, e) => {
        const claimsApprovalData = this.state.claims.map(claim => {
            if (claim.caseNumber === caseNumber) {
                return {
                    ...claim,
                    approvalStatus: e.target.value
                }
            }
            else {
                return {
                    ...claim
                }
            }
        });

        this.setState({ claims: claimsApprovalData });
        const filterData = claimsApprovalData.filter(o => o.approvalStatus);
        this.setState({ claimApproval: filterData });
    }

    saveChangesClaimApproval = () => {
        const canApproveRejectClaim = this.state.canApproveRejectClaim;
        const approvalClaims = this.state.claimApproval;

        if (canApproveRejectClaim && approvalClaims.length > 0) {
            this.setState({ fetching: true });
            approvalClaims.map(claim => {
                const data = {
                    caseNumber: claim.caseNumber.toString(),
                    approved: claim.approvalStatus === "1" ? "true" : "false",
                    fileName: `${claim.billToCustomer}-${claim.rmA_PONumber}${claim.documentType}`,
                    customerId: this.state.customerId,
                    fullName: this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName,
                    emailAddress: this.props.currentUser.email
                }

                ClaimService.claimApproval(data, this.state.customerId).then(
                    res => {
                        if (res) {
                            data.approved ? Notifications.message(this.localized.ClaimApproved) : Notifications.message(this.localized.ClaimRejected);

                        } else {
                            Notifications.error(this.localized.ClaimApprovalError);
                        }
                        this.updateClaims();
                    });
            })
        }
    }

    resetChanges = () => {
        this.setState({
            claimApproval: []
        });
        const claims = this.state.claims;
        if (claims?.length > 0) {
            const claimsApprovalData = claims.map(claim => {
                return {
                    ...claim,
                    approvalStatus: ''
                }
            });
            this.setState({ claims: claimsApprovalData });
        }
    }
    
    renderClaimSubmission = () => {
        return (
            <ClaimsSubmission
                setCommentsSubmit={this.setCommentsSubmit}
                setDetailsCommentSubmit={this.setDetailsCommentSubmit}
                setItemNumberSubmit={this.setItemNumberSubmit}
                setSerialNumberSubmit={this.setSerialNumberSubmit}
                setDateInstalledSubmit={this.setDateInstalledSubmit}
                setDateFailedSubmit={this.setDateFailedSubmit}
                setReferenceNumberSubmit={this.setReferenceNumberSubmit}
                setLoyaltyIDSubmit={this.setLoyaltyIDSubmit}
                setSerialNumberWarning={this.setSerialNumberWarning}
                setClaimCredit={this.setClaimCredit}
                setDuplicateSerial={this.setDuplicateSerial}
                commentsSubmit={this.state.commentsSubmit}
                detailsCommentSubmit={this.state.detailsCommentSubmit}
                itemNumberSubmit={this.state.itemNumberSubmit}
                serialNumberSubmit={this.state.serialNumberSubmit}
                dateInstalledSubmit={this.state.dateInstalledSubmit}
                dateFailedSubmit={this.state.dateFailedSubmit}
                referenceNumberSubmit={this.state.referenceNumberSubmit}
                loyaltyIDSubmit={this.state.loyaltyIDSubmit}
                removeClaimItem={this.removeClaimItem}
                uploadClaimAttachments={this.uploadClaimAttachments}
                addClaimItem={this.addClaimItem}
                validateFastTrack={this.validateFastTrack}
                bypassFastTrackValidation={this.bypassFastTrackValidation}
                submitClaim={this.submitClaim}
                claimLines={this.state.claimLines}
                handleEnter={this.handleEnter}
                dateFormat={this.props.StorefrontConfig.settingsList.dateFormat}
                itemNumberError={this.state.itemNumberError}
                commentsError={this.state.commentsError}
                installDateError={this.state.installDateError}
                failureDateError={this.state.failureDateError}
                serialNumberWarning={this.state.serialNumberWarning}
                loyalityIDWarning={this.state.loyalityIDWarning}
                referenceNumberError={this.state.referenceNumberError}
                setClaimType={this.setClaimType}
                claimType={this.state.claimType}
                claimTypeOptionError={this.state.claimTypeOptionError}
                serialNumberClassName={this.state.serialNumberClassName}
                handleBlur={this.handleBlur}
                returnPolicy={this.state.returnPolicy}
                obsolete={this.state.obsolete}
                duplicateSerial={this.state.duplicateSerial}
                claims={this.state.claims}
                serialNumberErrors={this.state.serialNumberErrors}
                fetching={this.state.fetching}
                canApproveRejectClaim={this.state.canApproveRejectClaim}
                isValidClaim={this.state.isValidClaim}
                pioneerBrand={this.state.pioneerBrand}
                isPioneerItem={this.state.isPioneerItem}
                setAnalysisOnlyClaimType={this.setAnalysisOnlyClaimType}
                setPioneerItem={this.setPioneerItem}
                itemDispositions={this.state.itemDispositions}
            />);
    }

    renderClaimFilter = () => {
        return (
            <ClaimsFilter
                dateRange={this.state.dateRange}
                statusFilter={this.state.status}
                setDateRange={this.setDateRange}
                setStatus={this.setStatus}
                fetchClaims={this.updateClaims}
                claims={this.state.claims}
                canApproveRejectClaim={this.state.canApproveRejectClaim}
                saveChangesClaimApproval={this.saveChangesClaimApproval}
                resetChanges={this.resetChanges}
                claimStatusFilters={this.state.claimStatusFilters}
                searchValue={this.state.searchValue}
                setSearchValue={this.setSearchValue}
            />);
    }

    renderClaims = () => {
        const hasSubmit = this.props.currentUser.roles.includes(Roles.SUBMIT_CLAIM);
        const hasShipToOrBothAddressType = UserHelper.checkCustomerAddressType(this.props.selectedCustomer.addressType);
        return (
            <div>
                {
                    hasSubmit && hasShipToOrBothAddressType &&
                    this.renderClaimSubmission()
                }
                <br />
                {
                    this.renderClaimFilter()
                }
                <Claims
                    claims={this.state.claims}
                    fetching={this.state.fetching}
                    renderPaging={this.renderPaging}
                    approvalStatus={this.state.approvalStatus}
                    setClaimApproval={this.setClaimApproval}
                    statusCodes={this.state.statusCodes}
                    claimsStatus={this.state.claimsStatus}
                    roles={this.props.currentUser.roles }
                />
            </div>
        );
    }

    renderPaging = () => {
        return (
            <Paging activePage={this.state.page} pageCount={this.pageCount} nextPage={this.nextPage} previousPage={this.previousPage} />
        );
    }

    render() {
        const { selectedCustomer } = this.props;

        return (
            <React.Fragment>
                {this.state.showNonFastTrackModal &&
                    <NonFastTrackModal setShowModal={this.setShowModal} claims={this.state.claimLines} addNonFastTrackFields={this.executeSubmit} ></NonFastTrackModal>
                }
                {selectedCustomer &&
                    <React.Fragment>
                        {
                            this.renderClaims()
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

}

ClaimHistory.propTypes = {
    selectedCustomer: PropTypes.object,
    currentUser: PropTypes.object,
    dateFormat: PropTypes.string
}

function mapStateToProps(state) {
    return {
        selectedCustomer: state.user.selectedCustomer,
        currentUser: state.user.currentUser,
    };
}

export default connect(
    mapStateToProps,
    null
)(withWarrantyConfig(withStorefrontConfig(withSelectedCustomer(ClaimHistory))));