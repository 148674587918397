import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import AvailabilityLogReportFilter from './AvailabilityLogReportFilter';
import LoggingService from '../../services/LoggingService';
import LoggedItems from './LoggedItems';
import Paging from '../Paging';
import { useCustomers } from '../../hooks/CustomerContext';
import { useSalesHistoryConfig } from '../../hooks/SalesHistoryConfigContext';

function AvailabilityLogReport(props) {
    const date = new Date().setDate(new Date().getDate() - 30);
    const { currentCustomer: selectedCustomer } = useCustomers();
    const salesHistoryConfig = useSalesHistoryConfig();
    const localized = salesHistoryConfig.labels;
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState("");
    const [fetching, setFetching] = useState(false);
    const [items, setItems] = useState(null);
    const [count, setCount] = useState(null);
    const [pageSize, setPageSize] = useState(25);
    const [customer, setCustomer] = useState(0);
    const [itemNumber, setItemNumber] = useState(null);
    const [startDate, setStartDate] = useState(new Date(date));
    const [endDate, setEndDate] = useState(new Date());
    const [territory, setTerritory] = useState(0);
    const [businessUnit, setBusinessUnit] = useState(0);

    const firstRender = useRef(true);
    useEffect(() => {
        let mounted = true;
        if (firstRender.current) {
            firstRender.current = false;
            fetchItems();
            return;
        }
        if (mounted) fetchItems();

        return () => { mounted = false; return null; }
    }, [territory, businessUnit, startDate, endDate, itemNumber, page, status, customer]);

    const fetchItems = () => {
        setFetching(true);
        LoggingService.fetchLoggedItems(customer, territory, businessUnit, itemNumber, page, pageSize, startDate, endDate).then(loaded);
    }

    const loaded = (data) => {
        if (data) {
            setItems(data.itemResponses);
            setCount(data.totalCount);
            setFetching(false);
        }
    }
    
    const pageCount = () => {
        return Math.ceil(count / pageSize)
    }

    const nextPage = () => {
        if (page < pageCount()) {
            setPage(page + 1);
        }
    }

    const previousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    const setFilterCustomer = (e) => {
        setCustomer(e.target.value);
        setPage(1);
    }

    const setFilterTerritory = (newTerritory) => {
        setTerritory(newTerritory);
        setPage(1);
    }
    const setFilterBusinessUnit = (newBusinessUnit) => {
        setBusinessUnit(newBusinessUnit);
        setPage(1);
    }
    const setFilterItemNumber = (e) => {
        setItemNumber(e.target.value);
        setPage(1);
    }

    const renderPaging = () => {
        return (
            <Paging activePage={page} pageCount={pageCount} nextPage={nextPage} previousPage={previousPage} />
        )
    }   

    return (
        <>            
            <div className="display-2" style={{ marginBottom: '25px' }}><b>{localized.AvailabilityLogReport}</b></div>
            <AvailabilityLogReportFilter
                businessUnit={businessUnit}
                setBusinessUnit={setFilterBusinessUnit}
                itemNumber={itemNumber}
                setItemNumber={setFilterItemNumber}
                customer={customer}
                setCustomer={setFilterCustomer}
                territory={territory}
                setTerritory={setFilterTerritory}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                fetchItems={fetchItems}               
            />            
          <LoggedItems
              items={items}
              fetching={fetching}
              renderPaging={renderPaging}
          />
        </>
    );
}

export default AvailabilityLogReport;