import React, { useState } from 'react';
import DoubleDownArrow from '../icons/DoubleDownArrow';
import AnimateHeight from 'react-animate-height';
import AngleDown from '../icons/AngleDown';
import { withInvoiceConfig } from '../../hooks/InvoiceConfigContext';

const DURATION = 500;
const ZERO = 0;
const Last30Days = 30;
const Last90Days = 90;
const LastYear = 365;
const Last2Years = 730;

export function InvoiceFilter(props) {
    const localized = props.InvoiceConfig.labels;
    const [animateHeight, setAnimateHeight] = useState(ZERO);
   console.log(props.dateRange)
    const toggleAnimation = () => {
        setAnimateHeight({
            animateHeight: animateHeight === ZERO ? "auto" : ZERO,
        })
    };

    const renderSortByFilter = () => {
        return(
            <div data-cy="sort-by" className="sort-by">
                <div className="label">{localized.SortByLabel}</div>
                <div className="fele-content">
                    <div className="select-wrapper">
                        <select onChange={props.setSortQuery} value={props.sortFilter}>
                            <option value="invoiceDate">{localized.InvoiceDateLabel}</option>
                            <option value="dueDate">{localized.DueDateLabel}</option>
                            <option value="number">{localized.InvoiceNumber}</option>
                            <option value="openAmount">{localized.OpenAmountLabel}</option>
                            <option value="grossAmount">{localized.GrossAmountLabel}</option>
                            <option value="discountDate">{localized.DiscountDateLabel}</option>
                        </select>
                        <AngleDown />
                    </div>
                </div>
            </div>
        );
    };

    const renderDateRangeFilter = () => {
        return (
            <div data-cy="date-range" className="date-range">
                <div className="label">{localized.DateRange}</div>
                <div className="fele-content">
                    <div className="select-wrapper">
                        <select onChange={props.setDateRangeQuery} value={props.dateRange} defaultChecked={props.dateRange }>
                            <option value={Last30Days}>{localized.Last30Days}</option>
                            <option value={Last90Days}>{localized.Last90Days}</option>
                            <option value={LastYear}>{localized.LastYear}</option>
                            <option value={Last2Years}>{localized.Last2Years}</option>
                        </select>
                        <AngleDown />
                    </div>
                </div>
            </div>
        );
    };

    const renderSearchFilter = () => {
        return (
            <div className="search">
                <div className="label">{localized.Search}</div>
                <div className="fele-content">
                    <div className="input-wrapper">
                        <input data-cy="invoice-search" type="text" placeholder={localized.Search} onChange={props.setNumberQuery} onKeyPress={props.handleEnter} value={props.numberFilter} />
                    </div>
                    <div className="button-wrapper small">
                        <button data-cy="invoice-submit-search" className="button search-button button-alt" onClick={props.search}>{localized.Search}</button>
                    </div>
                </div>
            </div>
        );
    };

    const renderInvoiceFilters = () => {
        const sortByFilter = renderSortByFilter();
        const dateRangeFilter = renderDateRangeFilter();
        const searchFilter = renderSearchFilter();

        return (
            <AnimateHeight duration={DURATION} height={animateHeight}>
                <div className="flex">
                    {sortByFilter}
                    {dateRangeFilter}
                    {searchFilter}
                </div>
            </ AnimateHeight>
        );
    };

    const renderSeperator = () => {
        return (
            <div className="seperator"></div>
        );
    };
    
    return (
        <div className="invoice-filters">
            <div className="button-wrapper filter-trigger">
                <button className="button" onClick={toggleAnimation}>
                    {localized.ButtonText_ShowFilters}
                    <DoubleDownArrow />
                </button>
            </div>
            {renderInvoiceFilters()}
            {renderSeperator()}
        </div>
    );
}

export default withInvoiceConfig(InvoiceFilter);