import PropTypes from 'prop-types';
import React from 'react';
import Calendar from '../form-controls/Calendar';
import ClaimLine from './ClaimLine';
import { withWarrantyConfig } from '../../hooks/WarrantyConfigContext';
import ErrorIcon from '../icons/Error';
import WarningIcon from '../icons/Warning';
import InfoHover from '../icons/InfoHover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import CommentDetailsModal from '../claims/CommentDetailsModal';

export class ClaimsSubmission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateInstalledSubmit: props.dateInstalledSubmit || null,
            dateFailedSubmit: props.dateFailedSubmit || null,
            minDate: new Date('Jan 1 1970'),
            needsValidation: false,
            itemNeedsValidation: false,
            acceptableObsolete: false,
            fetchingAsync: false,
            itemIsValid: false,
            canAdd: true,
            comment: props.commentsSubmit || null,
            detailComment: props.detailsCommentSubmit || '',
            commentDetailsModalOptions: {
                show: false,
                message: '',
                callback: () => { }
            },
        }
        this.stringLimit = 25;
    }

    updateDateInstalled = (e) => {
        this.setState({ dateInstalledSubmit: new Date(e) }, this.props.setDateInstalledSubmit(e));
    }

    updateDateFailed = (e) => {
        this.setState({ dateFailedSubmit: new Date(e) }, this.props.setDateFailedSubmit(e));
    }

    componentDidUpdate = (prevProps) => {
        if (this.props && prevProps.dateInstalledSubmit !== "" && this.props.dateInstalledSubmit === "") {
            this.setState({ dateInstalledSubmit: null });
            this.setState({ dateFailedSubmit: null });
        }
    }

    addLocalClaimItem = () => {
        this.setState({ needsValidation: true });
        this.props.addClaimItem().then(async (valid) => {
            if (valid) {
                this.setState({ needsValidation: false });
                this.setState({ detailComment: '' });
            }
        });
    }
    validateItemNumber = async (e) => {
        this.setState({ fetchingAsync: true });
        if (e.target.value.length > 0) {
            await this.props.validateFastTrack(e.target.value);
            this.setState({ itemNeedsValidation: true });
            this.setState({ acceptableObsolete: false });
            if (this.props.obsolete) {
                if (this.props.returnPolicy == 'F') {
                    this.props.setClaimCredit(true);
                    this.setState({ acceptableObsolete: true });
                    this.setState({ itemIsValid: true });
                    this.setState({ itemNeedsValidation: false });
                    this.setState({ canAdd: true });
                }
                else {
                    this.setState({ acceptableObsolete: false });
                    this.setState({ canAdd: false });
                    this.props.setClaimCredit(false);
                    this.setState({ itemIsValid: false });
                }
            }
            else {
                this.setState({ canAdd: true });
                this.setState({ itemIsValid: true });
                this.setState({ itemNeedsValidation: false });
                if (this.props.pioneerBrand && this.props.pioneerBrand.shown === true) {
                    this.props.setAnalysisOnlyClaimType();
                    this.props.setPioneerItem(true);
                }
                else {
                    this.props.setClaimCredit(false);
                }
            }
        }
        // clear pioneer item selected values
        if (e.target.value.length == 0) {
            this.props.setPioneerItem(false);
        }

        await this.validateSerialNumber(e.target.value, this.props.serialNumberSubmit);
    }

    validateSerialNumber = async (itemNumberIn, e) => {
        this.setState({ fetchingAsync: true });
        this.setState({ needsValidation: false });
        this.props.setSerialNumberWarning(false);
        if (this.props.claimLines.find(item => item.serialNumber === e && e.length >= 1 && !this.props.isPioneerItem)) {
            this.props.setDuplicateSerial(true);
            this.setState({ canAdd: false });
        }
        if (itemNumberIn === '5870205313C' || itemNumberIn === '5870205403C' || itemNumberIn === '5870205503C') {
            const serialYear = e.slice(0, 2);
            const serialMonth = e.slice(2, 3);
            const prior = ["A", "B", "C"]
            if ((serialYear == 24 && !prior.includes(serialMonth.toUpperCase())) || serialYear > 24) {
                this.props.bypassFastTrackValidation(' ', itemNumberIn)
            }
            else {
                this.props.bypassFastTrackValidation('F', itemNumberIn)
            }
        }

        else {
            this.props.setDuplicateSerial(false);
            if (this.state.itemIsValid) {
                this.setState({ canAdd: true });
            }
        }
        this.setState({ fetchingAsync: false });
    }
    commentHandleChange = (e) => {
        let comments = e.target.value;
        if (comments.length < this.stringLimit + 1) {
            this.props.setCommentsSubmit(e);
            this.setState({ comment: comments });
        }
    }

    longCommentClick = () => {
        this.setState({
            commentDetailsModalOptions: {
                show: true
            }
        });
    }
    hideConfirmation = () => {
        this.setState({
            commentDetailsModalOptions: {
                show: false
            }
        });
    }
    detailCommentUpdated = (detailComment) => {
        this.setState({ detailComment: detailComment });
        this.props.setDetailsCommentSubmit(detailComment);
    }

   
    render() {
        const {
            setCommentsSubmit, setDetailsCommentSubmit, setItemNumberSubmit, setSerialNumberSubmit, setDateInstalledSubmit, setSerialNumberWarning, setDateFailedSubmit, setReferenceNumberSubmit, setLoyaltyIDSubmit,
            commentsSubmit, itemNumberSubmit, serialNumberSubmit, dateInstalledSubmit, dateFailedSubmit, referenceNumberSubmit, loyaltyIDSubmit,
            removeClaimItem, addClaimItem, validateFastTrack, submitClaim, claimLines, handleEnter, dateFormat,
            itemNumberError, commentsError, installDateError, failureDateError, loyalityIDWarning, serialNumberWarning, referenceNumberError,
            setClaimType, claimType, claimTypeOptionError, handleBlur, returnPolicy, obsolete, duplicateSerial, setDuplicateSerial, serialNumberClassName, serialNumberErrors, fetching,
            isValidClaim, canApproveRejectClaim, isPioneerItem, pioneerBrand, uploadClaimAttachments, itemDispositions } = this.props;       
        const localized = this.props.WarrantyConfig.labels;   
        this.stringLimit = 25;
        const stringLimittext = commentsSubmit ? this.stringLimit - commentsSubmit.length : this.stringLimit // reset the stringlimit
        return (
            <div>
                <div className="fele-order-history-filter">
                    <div className="d-flex pb-5">
                        <div className="claim-header">Submit Claim</div>
                        {
                            isPioneerItem &&
                            <div className="col-6 d-flex justify-content-end">

                                <div className="export-button button-wrapper px-3">
                                    <a className="button button-alt text-nowrap px-4" target="_blank"
                                        href={localized.DownloadPioneerWarrantyURL}
                                        style={{ paddingTop: 5, textDecoration: 'none' }} >
                                        <FontAwesomeIcon icon={faDownload} className="mr-3" />
                                        {' ' + localized.DownloadPioneerWarrantyText}
                                    </a>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="flex">
                        <div className="claim-submission-text">
                            <div className="label">{localized.ItemNumber}*</div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <input className={`form-control ${((this.state.needsValidation) || (this.state.itemNeedsValidation) || obsolete) && itemNumberSubmit.length > 0 ? (itemNumberError || itemNumberSubmit.length < 1 || obsolete ?
                                        ((returnPolicy === 'F' && obsolete) ? "warning-border" : "is-invalid") : "is-valid") : ""}`}
                                        data-cy="itemnumber-submit" type="text" onChange={e => { setItemNumberSubmit(e) }} onBlur={e => { this.validateItemNumber(e) }}
                                        onKeyUp={handleEnter} value={itemNumberSubmit} maxLength={25} onBlur={this.validateItemNumber} required />
                                </div>

                            </div>
                        </div>
                        <div className="claim-submission-text">
                            <div className="label">{localized.SerialNumber}*</div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <input data-cy="serialnumber-submit" type="text" onChange={e => { setSerialNumberSubmit(e) }} onBlur={e => { this.validateSerialNumber(itemNumberSubmit, e.target.value) }}
                                        className={`form-control ${pioneerBrand && pioneerBrand.shown === true && pioneerBrand.required == false ? "" : ((this.state.needsValidation)
                                            ? ((serialNumberWarning || serialNumberSubmit.length < 1 || serialNumberErrors ) ? "is-invalid" : "is-valid") : "")}`}
                                        onKeyUp={handleEnter} value={serialNumberSubmit} maxLength={30} required />
                                    <div className="invalid-feedback">{serialNumberWarning ? localized.DuplicateSerialSubmitWarning : (serialNumberSubmit.length === 0 ? '' : localized.AlphanumericSubmitWarning)}</div>
                                </div>
                            </div>
                        </div>
                        <div className="claim-submission-text">
                            <div className="label">{localized.LoyaltyID}</div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <input data-cy="loyaltyid-submit" type="text" className={`form-control ${(this.state.needsValidation && loyaltyIDSubmit.length > 0) ? (loyalityIDWarning ? "is-invalid" : "is-valid") : ""}`}
                                        onChange={setLoyaltyIDSubmit} onKeyUp={handleEnter} value={loyaltyIDSubmit} maxLength={8} />
                                    <div className="invalid-feedback">*numerics only</div>
                                </div>
                            </div>
                        </div>
                        <div className="claim-submission-text" data-cy="dateInstalled-submit">
                            <div className="label">{localized.DateInstalled}{!isPioneerItem ? '*' : ''}</div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <Calendar date={this.state.dateInstalledSubmit} dateError={installDateError} minDate={this.state.minDate}
                                        needsValidated={pioneerBrand && pioneerBrand.shown === true ? '' : this.state.needsValidation}
                                        callBackFunction={this.updateDateInstalled} dateFormat={dateFormat} showIcon={true} />
                                </div>
                            </div>
                        </div>
                        <div className="claim-submission-text" data-cy="dateFailed-submit">
                            <div className="label">{localized.DateFailed}{!isPioneerItem ? '*' : ''}</div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <Calendar date={this.state.dateFailedSubmit} dateError={failureDateError} minDate={this.state.minDate}
                                        needsValidated={pioneerBrand && pioneerBrand.shown === true ? '' : this.state.needsValidation}
                                        callBackFunction={this.updateDateFailed} dateFormat={dateFormat} showIcon={true} />
                                </div>
                            </div>
                        </div>
                        <div className="claim-refNo">
                            <div className="label">{localized.ReferenceNumber}* <InfoHover toolTipMsg={localized.RefNoHoverTipMsg} /></div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <input className={`form-control ${(this.state.needsValidation) ? (referenceNumberError || referenceNumberSubmit.length < 1 ? "is-invalid" : "is-valid") : ""}`}
                                        data-cy="claim-reference-number" type="text" onChange={setReferenceNumberSubmit}
                                        onKeyUp={handleEnter} value={referenceNumberSubmit} maxLength={15} required />
                                </div>
                            </div>
                        </div>

                        <div className="claim-search">
                            <div className="label" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div>{localized.Comments}</div>
                                <div style={{ fontWeight: "normal", fontSize: '1.3rem' }}>*{stringLimittext}{" " + localized.charCount}</div>
                                <div className="button-wrapper" style={{ minWidth: '7rem', height: '1.7rem' }}>
                                    <button>
                                        <a className="link" style={{ fontWeight: "bold", fontSize: '1.2rem' }} onClick={() => this.longCommentClick()} > {"+Add Details"}</a>
                                    </button>
                                </div> 
                            </div>
                            <div className="fele-content">
                                <div className="input-wrapper">
                                    <input className={`form-control ${(this.state.needsValidation) ? (commentsError || commentsSubmit.length < 1 ? "is-invalid" : "is-valid") : ""}`}
                                        data-cy="comments-submit" type="text" onChange={this.commentHandleChange} onKeyUp={handleEnter} value={commentsSubmit} maxLength={25} required />
                                </div>
                            </div>
                        </div>
                        {
                            <div className="claim-replacement-option">
                                <div className="label">{localized.Type}*</div>
                                <div className="fele-content" style={this.state.canAdd === false || fetching || this.state.fetchingAsync ? { pointerEvents: "none", opacity: "0.4" } : {}}
                                    disabled={fetching || this.state.fetchingAsync || this.state.canAdd === false}>
                                    {
                                        (returnPolicy == "F" && obsolete) ? (<div className="filler">{localized.CreditOnly}</div>
                                        ) : (
                                            isPioneerItem ? <div className="filler">{localized.AnalysisOnly}</div>
                                                :

                                                <select data-cy="dispositionOption-submit" style={{ lineHeight: 2.2, fontSize: 'small' }}
                                                    onChange={setClaimType} value={claimType ? claimType.value : ''}
                                                    className={`form-select ${(this.state.needsValidation) ? ((claimTypeOptionError || claimType === "") ? 'is-invalid' : "is-valid") : ""}`} required>
                                                    <option value="">Select</option>
                                                    {
                                                        itemDispositions.length > 0 && itemDispositions?.map(
                                                            (disposition, index) =>
                                                                <option key={index} value={disposition.id}> {disposition.description}</option>)
                                                    }
                                                </select>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        <div className="claim-submission-button">
                            <div className="label">&nbsp;</div>
                            <div className="fele-content">
                                <div className="button-wrapper small">
                                    <button data-cy="claim-addItem" className="button search-button button-alt" style={this.state.canAdd === false || fetching || this.state.fetchingAsync ? { pointerEvents: "none", opacity: "0.4" } : {}} disabled={fetching || this.state.fetchingAsync || this.state.canAdd === false}
                                        onClick={this.addLocalClaimItem}>{localized.AddItem}</button>
                                </div>
                            </div>
                        </div>
                        <div className="claim-submission-button">
                            <div className="label">&nbsp;</div>
                            <div className="fele-content">
                                <div className="button-wrapper small">
                                    <button data-cy="claim-submit" className="button button-secondary search-button button-alt"
                                        onClick={submitClaim} disabled={claimLines.length === 0 || fetching || this.state.fetchingAsync}>{localized.SubmitClaim}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (obsolete && returnPolicy == "F" && itemNumberSubmit.length > 0) &&
                        <div className="alert alert-warning triangle-warning">
                            <WarningIcon></WarningIcon>
                            <span className="span-font">{localized.ObsoleteFastTrackWarning} </span>
                        </div>
                    }
                    {
                        (obsolete && returnPolicy != "F" && itemNumberSubmit.length > 0) &&
                        <div className="alert alert-danger circle-error">
                            <ErrorIcon></ErrorIcon>
                            <span className="span-font">{localized.ObsoleteNonFastTrackError} </span>
                        </div>
                    }
                    {
                        duplicateSerial &&
                        <div className="alert alert-danger circle-error">
                            <ErrorIcon></ErrorIcon>
                            <span className="span-font">{localized.DuplicateSerialNumberInCart} </span>
                        </div>
                    }
                    {
                        isValidClaim &&
                        <div className="alert alert-danger circle-error">
                            <ErrorIcon></ErrorIcon>
                            <span className="span-font">
                                {localized.DuplicateSerialNumberOnClaimSubmit}
                            </span>
                        </div>
                    }
                    {
                        !fetching && claimLines.map((claimLine, key) => (
                            <React.Fragment>
                                <div className="fele-claim-history">
                                    <div className="history">
                                        <ClaimLine value={key} claimLine={claimLine} removeItem={removeClaimItem} uploadFiles={uploadClaimAttachments} />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))
                    }

                    <div className="seperator" />
                </div>
                <CommentDetailsModal {...this.state.commentDetailsModalOptions}
                    onHide={this.hideConfirmation}
                    callback={this.hideConfirmation}
                    localized={localized}
                    itemNumber={itemNumberSubmit}
                    serialNumber={serialNumberSubmit}
                    detailCommentUpdated={this.detailCommentUpdated}
                    detailComment={this.state.detailComment}
                    showFooter={true}
                    IsDetailOnly={false }
                />
            </div>
        );
    }
}

ClaimsSubmission.propTypes = {
    claimLines: PropTypes.arrayOf(PropTypes.object),
    dateInstalled: PropTypes.object,
    dateFailed: PropTypes.object,
    dateFormat: PropTypes.string
}
export default (withWarrantyConfig(ClaimsSubmission))